<template>
	<div class="login_continer">
		<div class="login_box">
			<!-- 头像区域 -->
			<div class="avatar_box">
				<img src="../assets/logo.png">
			</div>
			<!-- 表单区域 -->
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
			  <el-form-item label="账号" prop="username">
			    <el-input v-model="ruleForm.username"></el-input>
			  </el-form-item>
			  <el-form-item label="密码" prop="password">
			    <el-input type="password" v-model="ruleForm.password"></el-input>
			  </el-form-item>
			  <el-form-item>
			      <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
			      <el-button @click="resetForm('ruleForm')">重置</el-button>
			  </el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	export default {
	    data() {
	      return {
	        ruleForm: {
	          username: '',
	          password: ''
	        },
	        rules: {
	          username: [
	            { required: true, message: '请输入账号', trigger: 'blur' },
	            { min: 3, max: 15, message: '账号长度在 3 到 15 个字符之间', trigger: 'blur' }
	          ],
	          password: [
	            { required: true, message: '请输入密码', trigger: 'change' },
	            { min: 3, max: 15, message: '密码长度在 3 到 15 个字符之间', trigger: 'blur' }
	          ]
	        }
	      };
	    },
	    methods: {
	      submitForm(formName) {
	        this.$refs[formName].validate(async valid => {
	          if (valid) {
	            // alert('submit!');
				// 预验证通过，后访问后台数据
				console.log('当前登录的用户名：----》',this.ruleForm.username)
				// 进行用户账号的判断
				if(!(this.ruleForm.username == '15621182793' || this.ruleForm.username == '15753951969')){
					this.$message.error('当前账号不可登陆')
	            // console.log('error submit!!');
					return false;
				}
				
				

				const {data} = await this.$http.post('bossLogin',qs.stringify(this.ruleForm))
				console.log(data)
				if(data.code !== 200){
					return this.$message.error(data.message)
				}
				this.$message.success(data.message)
				//存取token数据
				window.sessionStorage.setItem("satoken",data.data.tokenInfo.tokenValue)
				//存取user的数据
				window.sessionStorage.setItem("iNum",data.data.iNum)
				window.sessionStorage.setItem("cNum",data.data.cNum)
				window.sessionStorage.setItem("coNum",data.data.coNum)
				window.sessionStorage.setItem("sNum",data.data.sNum)
				
				this.$router.push("/home")
	          } else {
				this.$message.error('请您核对输入的账号、密码等信息')
	            // console.log('error submit!!');
	            return false;
	          }
	        });
	      },
	      resetForm(formName) {
	        this.$refs[formName].resetFields();
	      }
	    }
	  }
</script>

<style lang="less" scoped>
	.login_continer {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.login_box {
			width: 450px;
			height: 350px;
			background-color: #fff9f7;
			border-radius: 10px;

			.avatar_box {
				position: absolute;
				left: 50%;
				transform: translate(-50%, -50%);
				padding: 10px;
				width: 130px;
				height: 130px;
				border: 1px solid #eee;
				border-radius: 50%;
				box-shadow: 0 0 10px #ddd;
				background-color: #fff;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					background-color: #eeeeee;
				}
			}
			
			.demo-ruleForm{
				box-sizing: border-box;
				// position: absolute;
				bottom: 0;
				width: 100%;
				padding: 150px 45px;
			}
		}
	}
	
</style>
