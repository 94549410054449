<template>
	
	<div>
		<el-button class="addButton" type="primary" size="medium" @click="addcoursebefore">新增套餐</el-button>
		
		<!-- <el-row :gutter="20">
			<el-col :span="4">
				<div class="grid-content">
					
					<el-select v-model="queryInfo.c_id" placeholder="请选择大分类">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
				</div>
			</el-col>
			<el-col :span="2">
				<div class="grid-content">
					<el-button type="primary" @click="unionSearch()">搜索</el-button>
				</div>
			</el-col>
		</el-row> -->
		<!-- 校区列表展示 -->
		<el-table :data="courses" style="width: 100%" stripe border fit>
			<el-table-column label="套餐名称" >
				<template slot-scope="scope">
					<i class="el-icon-time"></i>
					<span >{{ scope.row.pname }}</span>
				</template>
			</el-table-column>
			<el-table-column label="套餐创建时间" >
				<template slot-scope="scope">
					<span >{{ scope.row.createTime | formatTimer}}</span>
				</template>
			</el-table-column>
			<el-table-column label="套餐价格" >
				<template slot-scope="scope">
					<span >{{ scope.row.pprice }}</span>
				</template>
			</el-table-column>
			<el-table-column label="套餐类型" >
				<template slot-scope="scope">
					<span v-if="scope.row.type === '1'" >机构套餐</span>
					<span v-if="scope.row.type === '2'" >用户套餐</span>
					<span v-if="scope.row.type === '3'" >开户套餐</span>
				</template>
			</el-table-column>
			<el-table-column label="套餐说明" >
				<template slot-scope="scope">
					<span>{{ scope.row.pinfo}}</span>
					
				</template>
			</el-table-column>
			<el-table-column label="查看次数" >
				<template slot-scope="scope">
					<span v-if="scope.row.isChacknum === null">无次数</span>
					<span v-if="scope.row.isChacknum !== null">{{scope.row.isChacknum}}</span>
				</template>
			</el-table-column>
			<el-table-column label="套餐时限(月)" >
				<template slot-scope="scope">
					<!-- <span v-if="scope.row.isChacknum === null">无次数</span> -->
					<span >{{scope.row.duration}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<!-- <el-button v-if="scope.row.deleted === 0" size="mini" type="success"
					@click="handleEdit(scope.$index, scope.row)">下架</el-button>
				<el-button v-if="scope.row.deleted !== 0" size="mini" type="success"
					@click="handleEdit(scope.$index, scope.row)">上架</el-button> -->
					<!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">详情</el-button> -->
					<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
					
				</template>
			</el-table-column>
		</el-table>




		<!-- 添加课程对话框的相关代码 -->
		<el-dialog @opened="show()" @closed="hide()" title="新增课程" :visible.sync="dialogFormVisible" destroy-on-close>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">			
				<el-form-item label="课程分类" prop="sid">
					<el-cascader :props="{ expandTrigger: 'hover' }" :options="options" v-model="ruleForm.sid"
						:show-all-levels="false"></el-cascader> &nbsp;&nbsp;&nbsp;&nbsp;
					<el-button type="primary" size="small" @click.once="addSubClassify" icon="el-icon-circle-plus-outline">添加小分类</el-button>
				</el-form-item>
				<el-form-item label="课程名称" prop="coName">
					<el-input v-model="ruleForm.coName"></el-input>
				</el-form-item>
				<el-form-item label="课程福利" prop="coGify">
					<el-input v-model="ruleForm.coGify"></el-input>
				</el-form-item>
				<el-form-item label="校区" prop="cId">
					<!-- <el-input v-model="ruleForm.cId"></el-input> -->
					<el-select v-model="ruleForm.cId" placeholder="请选择校区">
					    <el-option v-for="(item,index) in availableCampus" :key="item.c_id" :label="item.c_name" :value="item.c_id"></el-option>
					    <!-- <el-option label="区域二" value="beijing"></el-option> -->
					</el-select>
				</el-form-item>
				<el-form-item label="课程价值" prop="coPrice">
					<div>
					<el-input-number size="large" v-model="ruleForm.coPrice"
					 :precision="2" :step="0.1" :min="0" :max="3000"></el-input-number> 元
					 </div>
					 <span style="color: coral;">请参照全年班价格填写，课程价值需在合理范围内</span>
				</el-form-item>
				<el-form-item label="课程数量" prop="listen">
					<el-input v-model="ruleForm.listen" placeholder="例如:共4次课 2小时/次"></el-input>
				</el-form-item>
				<el-form-item label="课程大纲" prop="coContext">
					<el-input v-model="ruleForm.coContext" placeholder="请填写本次教学目标/目的/内容  例如: 少儿舞蹈基础与基本功训练"></el-input>
					<!-- <span style="color: coral;"></span> -->
				</el-form-item>
				<el-form-item label="课程展示图片" prop="image">
					<!-- <el-input v-model="ruleForm.image"></el-input> -->
					<el-upload v-model="ruleForm.image" :on-success="loadSuccess" :before-remove="removeImagebefore"
						:on-remove="removeImage" :http-request="ImgUploadRequest" list-type="picture-card" action=""
						:limit=1>
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<span style="color: coral;">机构品牌、课程品牌或Logo图片最佳</span>
				</el-form-item>
				<el-form-item label="课程简介" prop="coInfo">
					<div id="demo1"></div>
					<textarea name="" id="" cols="170" hidden rows="20" readonly
						v-model="ruleForm.coInfo"></textarea>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hide()">取 消</el-button>
				<el-button type="primary"  @click.once="submitForm('ruleForm')">提 交</el-button>
			</div>
		</el-dialog>
		
		
		<!-- 修改课程对话框的相关代码 -->
		<el-dialog @opened="showup()" @closed="hideup()" title="编辑优惠券" :visible.sync="dialogFormVisibleupdate"
			destroy-on-close>
			
			<el-form :model="updateRuleForm" :rules="updateRules" ref="updateRuleForm" label-width="140px"
				class="demo-ruleForm">
				<el-form-item label="套餐名称" prop="pName">
					  <el-input  placeholder="请填写套餐的名称" v-model="updateRuleForm.pName"></el-input>
				</el-form-item>
				<el-form-item label="套餐价格" prop="pPrice">
					<el-input type="number" v-model="updateRuleForm.pPrice"></el-input>
				</el-form-item>
				<el-form-item label="套餐类型" prop="type">
					<!-- <el-input type="textarea" :rows="2" placeholder="请输入优惠券介绍" v-model="ruleFormClassify.couInfo"></el-input> -->
					<el-select v-model="updateRuleForm.type" placeholder="请选择套餐类型">
					    <el-option
						  label="机构套餐"
					      value="1">
					    </el-option>
						<el-option
						  label="用户套餐"
						  value="2">
						</el-option>
						<el-option
						  label="开户套餐"
						  value="3">
						</el-option>
					  </el-select>
				</el-form-item>
				<el-form-item label="套餐说明" prop="pInfo">
					  <el-input type="textarea" :rows="2" placeholder="请填写套餐的说明" v-model="updateRuleForm.pInfo"></el-input>
				</el-form-item>
				<div v-if="updateRuleForm.type === '1'">
					<el-form-item label="查看次数" prop="isChacknum">
						  <el-input type="number"  placeholder="请填写查看次数" v-model="updateRuleForm.isChacknum"></el-input>
					</el-form-item>
				</div>
				<div v-if="updateRuleForm.type === '2'">
					<el-form-item label="套餐时限(月)" prop="duration">
						  <el-input type="number"  placeholder="请填写查看次数" v-model="updateRuleForm.duration"></el-input>
					</el-form-item>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hideup()">取 消</el-button>
				<el-button type="primary" @click.once="submitUpdateForm('updateRuleForm')">修 改</el-button>
			</div>
		</el-dialog>
		
		
		<!-- 添加优惠券对话框相关代码 -->
		<el-dialog @opened="showClassify()" @closed="hideClassify()" title="添加套餐" :visible.sync="dialogAddClassify" destroy-on-close>
			<el-form :model="ruleFormClassify" :rules="rulesClassify" ref="ruleFormClassify" label-width="140px" class="demo-ruleForm">			
				<el-form-item label="套餐名称" prop="pName">
					  <el-input  placeholder="请填写套餐的名称" v-model="ruleFormClassify.pName"></el-input>
				</el-form-item>
				<!-- <el-form-item label="套餐创建时间" prop="createTime">
					<el-date-picker
					      v-model="ruleFormClassify.createTime"
					      type="date"
					      placeholder="选择套餐开始时间">
					    </el-date-picker>
				</el-form-item> -->
				<!-- <el-form-item label="套餐价格" prop="couEndtime">
					<el-date-picker
					      v-model="ruleFormClassify.couEndtime"
					      type="date"
					      placeholder="选择优惠券开始时间">
					    </el-date-picker>
				</el-form-item> -->
				<el-form-item label="套餐价格" prop="pPrice">
					<el-input type="number" v-model="ruleFormClassify.pPrice"></el-input>
				</el-form-item>
				<el-form-item label="套餐类型" prop="type">
					<!-- <el-input type="textarea" :rows="2" placeholder="请输入优惠券介绍" v-model="ruleFormClassify.couInfo"></el-input> -->
					<el-select v-model="ruleFormClassify.type" placeholder="请选择套餐类型">
					    <el-option
						  label="机构套餐"
					      value="1">
					    </el-option>
						<el-option
						  label="用户套餐"
						  value="2">
						</el-option>
						<el-option
						  label="开户套餐"
						  value="3">
						</el-option>
					  </el-select>
				</el-form-item>
				<el-form-item label="套餐说明" prop="pInfo">
					  <el-input type="textarea" :rows="2" placeholder="请填写套餐的说明" v-model="ruleFormClassify.pInfo"></el-input>
				</el-form-item>
				<div v-if="ruleFormClassify.type === '1'">
					<el-form-item label="查看次数" prop="isChacknum">
						  <el-input type="number"  placeholder="请填写查看次数" v-model="ruleFormClassify.isChacknum"></el-input>
					</el-form-item>
				</div>
				<div v-if="ruleFormClassify.type === '2'">
					<el-form-item label="套餐时限(月)" prop="duration">
						  <el-input type="number"  placeholder="请填写套餐的时限" v-model="ruleFormClassify.duration"></el-input>
					</el-form-item>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hideClassify()">取 消</el-button>
				<el-button type="primary" @click="submitFormClassify('ruleFormClassify')">提 交</el-button>
			</div>
		</el-dialog>
		
		
		
		
		
		<!-- <div class="floot">
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.page" :page-sizes="[10, 15, 20, 25]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="Total">
			</el-pagination>
		</div> -->
		
		
	</div>
</template>

<script>
	import wangEditor from 'wangeditor'
	import OSS from 'ali-oss'
	import qs from 'qs'
	import {
		v4 as uuidv4
	} from 'uuid'

	import oss from '../../utils/oss'
	export default {
		created() {
			// this.iid = window.sessionStorage.getItem("iid") * 1
			// this.queryInfo.i_id = this.iid
			this.getCoursesList();
			// this.getAllClassify();
			// this.availableAllCampus();
		},
		data() {
			return {
				courses: [],
				iid: '',
				campuses: [],
				queryInfo: {
					type: 0
				},
				value: false,
				deletedParam: {
					id: 0
				},
				dialogFormVisible: false,
				dialogFormVisibleupdate: false,
				formLabelWidth: '120px',
				ruleForm: {
					coName: '',
					coGify: '',
					cId: '',
					coPrice: 0,
					coInfo: '',
					iId: 0,
					sId: 0,
					sid: [],
					listen: '',
					image: '',
					coContext: ''
				},
				updateRuleForm: {
					pId: '',
					pName: '',
					pPrice: 0,
					type: '',
					pInfo: '',
					isChacknum: null,
					duration: 0
				},
				ruleFormClassify:{
					pName: '',
					pPrice: 0,
					type: '',
					pInfo: '',
					isChacknum: null,
					duration: 0
				},
				updateData: {
					cName: '',
					address: '',
					image: '',
					describes: '',
					isPass: 1,
					cId: 0
				},
				updateImageTemp: [], //修改操作时新上传的图片文件暂存区
				updateImagedd: [], //修改操作全部文件的暂存区
				rules: {
					coName: [{
							required: true,
							message: '请输入课程名称',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 20,
							message: '长度在 3 到 20 个字符',
							trigger: 'blur'
						}
					],
					coPrice: [{
							required: true,
							message: '请输入课程价值',
							trigger: 'blur'
						}
					],
					coInfo: [{
						required: false,
						message: '请填写课程简介',
						trigger: 'blur'
					}],
					cId: [{
						required: true,
						message: '请选择校区',
						trigger: 'blur'
					}],
					sid: [{
						required: true,
						message: '请选择课程分类',
						trigger: 'blur'
					}],
					listen: [{
						required: true,
						message: '请填写课程数量',
						trigger: 'blur'
					}],
					image: [{
						required: true,
						message: '请上传课程图片',
						trigger: 'blur'
					}],
					coContext: [{
						required: true,
						message: '请填写课程大纲',
						trigger: 'blur'
					}]
				},
				rulesClassify: {
					pName: [{
							required: true,
							message: '请输入套餐的名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					pPrice: [{
						required: true,
						message: '请设置套餐价格',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择套餐类型',
						trigger: 'blur'
					}],
					pInfo: [{
						required: true,
						message: '请输入套餐的简介',
						trigger: 'blur'
					}]
					
				},
				updateRules: {
					pName: [{
							required: true,
							message: '请输入套餐的名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					pPrice: [{
						required: true,
						message: '请设置套餐价格',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择套餐类型',
						trigger: 'blur'
					}],
					pInfo: [{
						required: true,
						message: '请输入套餐的简介',
						trigger: 'blur'
					}]
				},
				editor: null,
				dialogImageUrl: '', //图片地址
				dialogVisible: false, //是否允许图片
				dialogVisibleupdate: false,
				imageArray: [],
				updateImageArray: [],
				options: [],
				availableCampus: [],
				dialogAddClassify: false,
				Total: 0,
				tatalPage: 0
				
			}
		},
		methods: {
			availableAllCampus(){
				this.$http({
					url: 'company/campus/availableAllCampus',
					method: 'GET',
					params: {iid: this.iid}
				}).then(res =>{
					// console.log("adasdas",res)
					this.availableCampus = res.data.data
					
				})
			},
			getAllClassify() {
				this.$http({
					url: 'company/subclassif/allClassify',
					method: 'GET'
				}).then(res => {
					// console.log("####",res)
					this.options = res.data.data
				})
			},
			addcoursebefore() {
				// this.$http({
				// 	url: 'company/campus/isPassNum',
				// 	params: {i_id: this.iid}
				// }).then(res=>{
				// 	console.log(res.data.data)
				// 	if (res.data.data === 0) {
				// 		this.$message.error('您还没有创建校区，或校区还没有审核通过，无法创建课程')
				// 		this.dialogFormVisible = false
				// 	}else{
				// 		this.dialogFormVisible = true
				// 	}
				// })
				// console.log('gogogo')
				// this.dialogFormVisible = true
				this.dialogAddClassify = true;
				
			},
			async getCoursesList() {
				const {
					data
				} = await this.$http.get('company/sys-user/allPlan',
						{
							params: this.queryInfo
						}
				)
				console.log(data)
				if (data.code !== 200) {
					this.$message.error(data.message)
				} else {
					if (data.data.length === 0) {
						this.courses = []
					} else {
						this.courses = data.data
						// this.Total = data.data.Total
						// this.tatalPage = data.data.tatalPage
					}
				}

			},
			//点击修改按钮时的方法
			handleEdit(index, row) {
				console.log(index, row);
				//在这里进行数据的赋值操作
				this.updateRuleForm.pId = row.pid
				this.updateRuleForm.pName = row.pname
				this.updateRuleForm.pPrice = row.pprice
				this.updateRuleForm.type = row.type
				this.updateRuleForm.pInfo = row.pinfo
				this.updateRuleForm.isChacknum = row.isChacknum
				this.updateRuleForm.duration = row.duration
				//打开弹出框
				this.dialogFormVisibleupdate = true
			},
			handleDelete(index, row) {
				console.log(index, row);
				// 进行优惠券的删除操作
				this.$http({
					url: 'company/sys-user/deletePlan',
					method: 'POST',
					data: qs.stringify({id: row.pid}) 
					}).then(res=>{
						if(res.data.code === 200){
							this.$message.success("套餐删除成功！")
						}else{
							this.$message.error("套餐删除失败！")
						}
						// console.log(res)
						this.getCoursesList()
					})
				
			},
			async deletedChange(campusesInfo) {
				// console.log(campusesInfo)
				//false的时候，deleted为0    true的时候deleted为1
				//进行商品的上下架操作
				this.deletedParam.id = campusesInfo.c_id
				console.log(this.deletedParam.id)
				if (campusesInfo.deleted === 0) {
					//下架					
					const {
						data
					} = await this.$http.post('company/course/deleteCourse', qs.stringify(this.deletedParam))
					// console.log(data)
					if (data.code !== 200) {
						return this.$message.error("操作失败，校区未成功下架")
					}
					campusesInfo.deleted = 1
					return this.$message.success("操作成功，校区已成功下架")
					//这个时候修改元数组
				} else {
					//上架
					const {
						data
					} = await this.$http.post('company/course/undeleteCourse', qs.stringify(this.deletedParam))
					// console.log(data)
					if (data.code !== 200) {
						return this.$message.error("操作失败，校区未成功上架")
					}
					campusesInfo.deleted = 0
					return this.$message.success("操作成功，校区已成功上架")
					//这个时候修改元数组
				}

			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//进行sid的数据充足操作
						this.ruleForm.sId = this.ruleForm.sid[1]
						this.$http({
							url: 'company/course/addCourse',
							method: 'POST',
							// header: {
							// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
							// },
							data: qs.stringify({
								coName: this.ruleForm.coName,
								coGify: this.ruleForm.coGify,
								cId: this.ruleForm.cId,
								coPrice: this.ruleForm.coPrice,
								coInfo: this.ruleForm.coInfo,
								iId: this.iid,
								sId: this.ruleForm.sId,
								listen: this.ruleForm.listen,
								coImg: this.ruleForm.image,
								coContext: this.ruleForm.coContext
							})
						}).then(res => {
							// console.log(res.data)
							// 开始进行校区的判断操作
							if (res.data.code === 200) {
								this.$message.success(res.data.message)
							} else {
								this.$message.error(res.data.message)
							}
							//进行弹出框的关闭操作
							this.dialogFormVisible = false
							this.ruleForm.coName = ''
							this.ruleForm.coGify = ''
							this.ruleForm.cId = ''
							this.ruleForm.coPrice = 0
							this.ruleForm.coInfo = ''
							this.ruleForm.sid = []
							this.ruleForm.sId = 0
							this.ruleForm.listen = '',
							this.ruleForm.image = '',
							this.ruleForm.coContext = ''
							this.imageArray = []
							this.getCoursesList()

						})

					} else {
						// console.log('error submit!!');
						this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
						return false;
					}
				});
			},
			submitFormClassify(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//进行sid的数据充足操作
						this.$http({
							url: 'company/sys-user/addPlan',
							method: 'POST',
							// header: {
							// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
							// },
							data: qs.stringify(this.ruleFormClassify)
						}).then(res => {
							// console.log(res.data)
							// 开始进行校区的判断操作
							if (res.data.code === 200) {
								this.$message.success(res.data.message)
							} else {
								this.$message.error(res.data.message)
							}
							//进行弹出框的关闭操作
							this.dialogAddClassify = false
							this.ruleFormClassify.couTitle = ''
							this.ruleFormClassify.couStarttime = ''
							this.ruleFormClassify.couEndtime = ''
							this.ruleFormClassify.couMoney = 0
							this.ruleFormClassify.couInfo = ''
							this.getCoursesList()
							// this.getAllClassify()
						})
					} else {
						// console.log('error submit!!');
						this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
						return false;
					}
				});
			},
			submitUpdateForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//进行sid的数据充足操作
						// this.updateRuleForm.sId = this.updateRuleForm.sid[1]
						this.$http({
							url: 'company/sys-user/updatePlan',
							method: 'POST',
							// header: {
							// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
							// },
							data: qs.stringify(this.updateRuleForm)
						}).then(res => {
							// console.log(res.data)
							// 开始进行校区的判断操作
							if (res.data.code === 200) {
								this.$message.success(res.data.message)
							} else {
								this.$message.error(res.data.message)
							}
							//进行弹出框的关闭操作
							this.dialogFormVisibleupdate = false
							this.updateRuleForm.s_id = ''
							this.updateRuleForm.c_id = ''
							this.updateRuleForm.s_name = ''
							this.getCoursesList()

						})

					} else {
						// console.log('error submit!!');
						this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			beforeDestroy() {
				// 调用销毁 API 对当前编辑器实例进行销毁
				this.editor.destroy()
				this.editor = null
			},
			getEditorData() {
				// 通过代码获取编辑器内容
				let data = this.editor.txt.html()
				// alert(data)
			},
			//显示添加校区的富文本
			show() {
				const editor = new wangEditor('#demo1')
				// 配置 onchange 回调函数，将数据同步到 vue 中
				editor.config.onchange = (newHtml) => {
					this.ruleForm.coInfo = newHtml
				}

				let client = oss.createOssClient1()
				editor.config.customUploadImg = function(resultFiles, insertImgFn) {
					// resultFiles 是 input 中选中的文件列表
					// insertImgFn 是获取图片 url 后，插入到编辑器的方法
					client.put(uuidv4(), resultFiles[0])
						.then(function(res) {
							console.log(res)
							// 上传图片，返回结果，将图片插入到编辑器中
							insertImgFn(res.url)
						}).catch(function(err) {
							console.log(err)
						})
					// oss.ossUploadFile(uuidv4(), resultFiles[0])
					// 	.then(function(res) {
					// 		console.log(res)
					// 		// 上传图片，返回结果，将图片插入到编辑器中
					// 		insertImgFn(res.url)
					// 	}).catch(function(err) {
					// 		console.log(err)
					// 	})	
				}

				// 创建编辑器
				editor.create()
				this.editor = editor
			},
			showClassify(){
				this.dialogAddClassify = true
			},
			hideClassify(){
				this.dialogAddClassify = false
				// 清空弹出层的所有数据
				this.ruleFormClassify.cId = ''
				this.ruleFormClassify.sName = ''
				
			},
			showup() {
				
			},
			//隐藏富文本
			hide() {
				// this.$message.success("asdsadsa")
				this.dialogFormVisible = false
				this.ruleForm.address = ''
				this.ruleForm.cName = ''
				this.ruleForm.image = ''
				this.ruleForm.describes = ''
				// 批量删除oss图片
				// this.imageArray.forEach(item => {
				// 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
				// 	if (isDeleted) {
				// 		console.log("删除成功")
				// 	} else {
				// 		console.log("删除失败")
				// 	}
				// })
				this.imageArray = []

			},
			hideup() {
				// this.$message.success("asdsadsa")
				this.updateRuleForm.c_id = ''
				this.updateRuleForm.s_name = ''				
				this.updateRuleForm.s_id = ''
				this.dialogFormVisibleupdate = false
				// 批量删除oss图片
				// this.updateImageTemp.forEach(item => {
				// 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
				// 	if (isDeleted) {
				// 		console.log("删除成功")
				// 	} else {
				// 		console.log("删除失败")
				// 	}
				// })
				// this.updateImageTemp = []			
			},
			// 删除图片
			// handleRemove(file, fileList) {
			// 	console.log(file, fileList);
			// },
			// // 图片回显
			// handlePictureCardPreview(file) {
			// 	this.dialogImageUrl = file.url;
			// 	this.dialogVisible = true;
			// },
			async ImgUploadRequest(option) {
				// console.log("进入上传方法")
				oss.ossUploadFile(option).then(res => {
					// console.log(res.res.requestUrls)
					var url = ''
					if(res.res.requestUrls[0].indexOf('?uploadId') !== -1){
						url = res.res.requestUrls[0].slice(0,res.res.requestUrls[0].indexOf('?uploadId'))
					}else{
						url = res.res.requestUrls[0]
					}
					// this.imageArray.push(res.res.requestUrls[0])
					this.imageArray.push(url)
					// console.log(this.imageArray)
					// this.ruleForm.image = this.imageArray.join(',')
					this.ruleForm.image = url
					console.log('%%%%%',this.ruleForm.image)
				})
			},
			async ImgUploadRequestup(option) {
				// console.log("进入上传方法")
				oss.ossUploadFile(option).then(res => {
					//向image中添加图片
					// console.log(res.res.requestUrls[0].indexOf('?uploadId'))
					var urll = ''
					if(res.res.requestUrls[0].indexOf('?uploadId') !== -1){
						urll = res.res.requestUrls[0].slice(0,res.res.requestUrls[0].indexOf('?uploadId'))
					}else{
						urll = res.res.requestUrls[0]
					}
					console.log('qqqq',urll)
					let obj = {
						// url: res.res.requestUrls[0]
						url: urll
					}
					this.updateImagedd.push(obj)
					let aa = this.updateImagedd.map(v => v.url)
					this.updateRuleForm.image = aa.join()
					console.log('##', this.updateRuleForm.image)
				})
			},
			removeImage(file, fileList) {},
			// 删除之前，对图片数据进行维护(添加校区)
			removeImagebefore(file, fileList) {

				//1调用图片删除的api
				var isDeleted = oss.ossDeleteImages(file)
				if (isDeleted) {
					// 2清除图片的的对应的数组数据
					// console.log(this.imageArray)
					this.imageArray.splice(this.imageArray.findIndex(item => item === file.response.res.requestUrls[0], 1))
					// console.log(this.imageArray)
					if (this.imageArray.length !== 0) {
						this.ruleForm.image = this.imageArray.join(",")
					} else {
						this.ruleForm.image = ''
					}
					this.$message.success("删除成功")
				} else {
					this.$message.success("删除失败")
				}
				return isDeleted
			},
			//  删除之前，对图片数据进行维护(修改校区)
			// 注意：修改校区的时候最后一张图片不能进行删除
			removeImagebeforeup(file, fileList) {
				console.log(this.updateImagedd)
				// console.log(file.response.res.requestUrls[0])
				// if (this.updateImagedd.length === 1) {
				// 	this.$message.error('请至少保留一张图片')
				// 	return false
				// }
				//获取删除文件的下标
				let img = this.updateImagedd.map(v => v.url)
				var Index = img.findIndex(item => item === file.url)
				//1调用图片删除的api
				// console.log('##',fileList)
				var fileName = file.url.substr(file.url.indexOf('/image/'))
				// console.log('$$',fileName)
				var isDeleted = oss.ossDeleteImage(fileName)

				if (isDeleted) {
					// 2清除图片的的对应的数组数据
					this.updateImagedd.splice(Index, 1)
					img = this.updateImagedd.map(v => v.url)
					this.updateRuleForm.image = img.join()
					//进行数据库的删除操作
					this.deleteImage()
					this.getCampusList()
					return true
				} else {
					this.$message.success("删除失败")
					return false
				}
			},
			loadSuccess(file, fileList) {
				// console.log("上传成功！", file, fileList)
			},
			deleteImage() {
				this.$http({
					url: 'company/campus/updateImagesById',
					method: 'POST',
					// header: {
					// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
					// },
					data: qs.stringify({
						id: this.updateRuleForm.cId,
						img: this.updateRuleForm.image
					})
				}).then(res => {
					if (res.data.code === 200) {
						this.$message.success("删除成功")
					}
					return res.data
				})
			},
			addSubClassify(){
				// console.log("点了添加小分类按钮")
				this.dialogAddClassify = true;
				//添加弹出层
			},
			unionSearch() {
				this.$http({
					url: 'company/coupons/getCouponsList',
					params: this.queryInfo
				}).then(res => {
					console.log(res)
					//进行页面元素的赋值操作
					this.Total = res.data.data.Total
					this.tatalPage = res.data.data.tatalPage
					this.courses = res.data.data.allCouponsList
				})
			},
			handleSizeChange(val) {
				// console.log(val);
				//设置页面请求参数
				this.queryInfo.pageSize = val
				this.queryInfo.page = 1
				//重新请求数据
				this.$http({
					url: 'company/coupons/getCouponsList',
					params: this.queryInfo
				}).then(res => {
					console.log(res)
					//进行页面元素的赋值操作
					this.Total = res.data.data.Total
					this.tatalPage = res.data.data.tatalPage
					this.courses = res.data.data.allCouponsList
				})
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.queryInfo.page = val
				this.$http({
					url: 'company/coupons/getCouponsList',
					params: this.queryInfo
				}).then(res => {
					console.log(res)
					//进行页面元素的赋值操作
					this.Total = res.data.data.Total
					this.tatalPage = res.data.data.tatalPage
					this.courses = res.data.data.allCouponsList
				})
			},

		},
		//格式化日期
		filters: {
			formatTimer: function(value) {
				let date = new Date(value);
				let y = date.getFullYear();
				let MM = date.getMonth() + 1;
				MM = MM < 10 ? "0" + MM : MM;
				let d = date.getDate();
				d = d < 10 ? "0" + d : d;
				let h = date.getHours();
				h = h < 10 ? "0" + h : h;
				let m = date.getMinutes();
				m = m < 10 ? "0" + m : m;
				let s = date.getSeconds();
				s = s < 10 ? "0" + s : s;
				return y + "-" + MM + "-" + d;
			}
		}
	}
</script>

<style scoped lang="less">
	.addButton {
		margin-bottom: 20px;
	}
		
	.home {
		width: 100%;
		margin-right: 20px;
		position: relative;
	}
		
	.floot {
		// position: fixed;
		// bottom: 40px;
		margin-top: 20px;
		
		// justify-content: center;
		.el-pagination {
			margin: 0 auto;
		}
	}
		
	.el-row {
		margin-bottom: 20px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
		
	.el-col {
		border-radius: 4px;
	}
		
	.bg-purple-dark {
		background: #99a9bf;
	}
		
	.bg-purple {
		background: #d3dce6;
	}
		
	.bg-purple-light {
		background: #e5e9f2;
	}
		
	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}
		
	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
</style>
