import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Home from '../views/Home'
import Welcome from '../views/Welcome'
import User from '../views/user/user'
import Institution from '../views/institution/institution'
import Campus from '../views/institution/campus'
import Subscribe from '../views/subscribe/subscribe'
import CampusReview from '../views/review/campusReview'
import CourseReview from '../views/review/courseReview'
import Classify from '../views/myset/classify'
import Swipers from '../views/myset/swipers'
import Coupons from '../views/coupons/coupons'
import Plans from '../views/plans/plans'
import Subclassify from '../views/myset/subclassify'
import Order from '../views/order/order'
import Settled from '../views/setlled/settled'
import Linked from '../views/linked/linked'
import Shared from '../views/shared/shared'
import Worker from '../views/worker/worker'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	redirect: '/login'
},
{
	path: '/login',
	component: Login
},
{
	path: '/home',
	component: Home,
	redirect: "/welcome",
	children: [{
			path: "/welcome",
			component: Welcome
		},
		{
			path: "/user",
			component: User
		},
		{
			path: "/institution",
			component: Institution
		},
		{
			path: "/campus",
			component: Campus
		},
		{
			path: "/subscribe",
			component: Subscribe
		},
		{
			path: "/campusReview",
			component: CampusReview
		},
		{
			path: "/courseReview",
			component: CourseReview
		},
		{
			path: "/classify",
			component: Classify
		},
		{
			path: "/swipers",
			component: Swipers
		},
		{
			path: "/coupons",
			component: Coupons
		},
		{
			path: "/plans",
			component: Plans
		},
		{
			path: "/subclassify",
			component: Subclassify
		},
		{
			path: "/order",
			component: Order
		},
		{
			path: "/settled",
			component: Settled
		},
		{
			path: "/linked",
			component: Linked
		},
		{
			path: "/shared",
			component: Shared
		},
		{
			path: "/worker",
			component: Worker
		},
		
	]

}

]

const router = new VueRouter({
	routes
})

//挂载路由导航守卫
router.beforeEach((to, from, next) => {
	if (to.path === '/login') return next()
	const token = window.sessionStorage.getItem('satoken')
	if (!token) return next('/login')
	next();
})

export default router
