<template>
	<div>
		<!-- <el-button class="addButton" type="primary" size="medium" @click="addcoursebefore">添加机构</el-button> -->
	<!-- 展示列表 -->
			<el-row :gutter="20">
				<el-col :span="4">
					<div class="grid-content">
						<el-input v-model="queryInfo.c_name" placeholder="请输入课程名称"></el-input>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="grid-content">
						<el-input v-model="queryInfo.c_name" placeholder="请输入校区名称"></el-input>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="grid-content">
						<el-input v-model="queryInfo.i_name" placeholder="请输入机构名称"></el-input>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="grid-content">
						<el-date-picker clearable v-model="queryInfo.starttime" type="date" align="center" placeholder="创建起始日期"
							format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss">
						</el-date-picker>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="grid-content">
						<el-date-picker clearable v-model="queryInfo.endtime" type="date" align="center" placeholder="创建结束日期"
							format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss">
						</el-date-picker>
					</div>
				</el-col>
				<!-- <el-col :span="4">
					<div class="grid-content">
						<el-select clearable v-model="queryInfo.is_pass" placeholder="请选择审核状态">
							<el-option label="等待审核" value="1">
							</el-option>
							<el-option label="审核通过" value="2">
							</el-option>
							<el-option label="审核不通过" value="3">
							</el-option>
						</el-select>
					</div>
				</el-col> -->
				<el-col :span="2">
					<div class="grid-content">
						<el-button type="primary" @click="unionSearch()">搜索</el-button>
					</div>
				</el-col>
			</el-row>
			<!-- <el-button class="addButton" type="primary" size="medium" @click="addcoursebefore">新增课程</el-button> -->
			<!-- 用户列表展示 -->
			<el-table :data="courses" style="width: 100%" stripe border fit>
				<el-table-column label="课程名称" >
					<template slot-scope="scope">
						<i class="el-icon-time"></i>
						<span >{{ scope.row.co_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="校区名称" >
					<template slot-scope="scope">
						<i class="el-icon-time"></i>
						<span >{{ scope.row.c_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="所属机构" >
					<template slot-scope="scope">
						<i class="el-icon-time"></i>
						<span >{{ scope.row.i_name }}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column label="校区地址" width="300">
					<template slot-scope="scope">
						<i class="el-icon-time"></i>
						<span style="margin-left: 10px">{{ scope.row.address }}</span>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="校区状态" width="150">
					<template slot-scope="scope">
						<span style="margin-left: 10px">{{ scope.row.}}</span>
					</template>
				</el-table-column> -->
				<el-table-column label="创建时间" >
					<template slot-scope="scope">
						<span >{{ scope.row.create_time | formatTimer}}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column label="校区数量" width="150">
					<template slot-scope="scope">
						<span style="margin-left: 10px">{{ scope.row.campusNum}}</span>
					</template>
				</el-table-column> -->
				<el-table-column label="大分类">
					<template slot-scope="scope">
						<span >{{ scope.row.cl_name }}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="小分类">
					<template slot-scope="scope">
						<span >{{ scope.row.s_name }}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="到店礼">
					<template slot-scope="scope">
						<span v-if="scope.row.co_gify !==null" >{{ scope.row.co_gify }}</span>
						<span v-if="scope.row.co_gify ===null" >无</span>
					</template>
				</el-table-column>
				<!-- <el-table-column label="会员状态" width="150">
					<template slot-scope="scope">
						<span style="margin-left: 10px">{{ scope.row.sex }}</span>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="会员过期时间" width="300">
					<template slot-scope="scope">
						<i class="el-icon-time"></i>
						<span style="margin-left: 10px">{{ scope.row.overdue_time | formatTimer}}</span>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="剩余查看次数" width="180">
					<template slot-scope="scope">
						<span style="margin-left: 10px">{{ scope.row.check_num }}</span>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="上下架状态" width="200">
					<template slot-scope="scope">
						<span v-if="scope.row.deleted === 0" style="margin-left: 10px;color: burlywood;">上架</span>
						<span v-if="scope.row.deleted === 1" style="margin-left: 10px;color: #42B983;">下架</span>
					</template>
				</el-table-column> -->
				<el-table-column label="审核状态" >
					<template slot-scope="scope">
						<span v-if="scope.row.is_pass === 1" style="color: burlywood;">等待审核</span>
						<span v-if="scope.row.is_pass === 2" style="color: #42B983;">审核通过</span>
						<span v-if="scope.row.is_pass === 3" style="color: crimson;">审核不通过</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						
						<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">审核</el-button>
						
					</template>
				</el-table-column>
			</el-table>
	
	
	
	
			<!-- 添加课程对话框的相关代码 -->
			<el-dialog @opened="show()" @closed="hide()" title="机构开户" :visible.sync="dialogFormVisible" destroy-on-close>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
					<!-- <el-form-item label="课程分类" prop="sid">
						<el-cascader :props="{ expandTrigger: 'hover' }" :options="options" v-model="ruleForm.sid"
							:show-all-levels="false"></el-cascader> &nbsp;&nbsp;&nbsp;&nbsp;
						<el-button type="primary" size="small" @click="addSubClassify" icon="el-icon-circle-plus-outline">
							添加小分类</el-button>
					</el-form-item> -->
					<el-form-item label="机构名称" prop="i_name">
						<el-input v-model="ruleForm.i_name"></el-input>
					</el-form-item>
					<el-form-item label="联系人手机号" prop="phone">
						<el-input v-model="ruleForm.phone"></el-input>
					</el-form-item>
					
					<el-form-item label="剩余查看次数" prop="check_num">
						<el-input type="number" v-model="ruleForm.check_num"></el-input>
					</el-form-item>
					<!-- <el-form-item label="校区" prop="cId">
						
						<el-select v-model="ruleForm.cId" placeholder="请选择校区">
							<el-option v-for="(item,index) in availableCampus" :key="item.c_id" :label="item.c_name"
								:value="item.c_id"></el-option>
							
						</el-select>
					</el-form-item> -->
					<el-form-item label="开户状态" prop="is_vip">
						<el-select clearable v-model="ruleForm.is_vip" placeholder="请选择开户状态">
							<el-option label="已开户" value="2">
							</el-option>
							<el-option label="未开户" value="1">
							</el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="课程简介" prop="coInfo">
	
						<div id="demo1"></div>
						<textarea name="" id="" cols="170" hidden rows="20" readonly v-model="ruleForm.coInfo"></textarea>
					</el-form-item> -->
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="hide()">取 消</el-button>
					<el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
				</div>
			</el-dialog>
	
	
			<!-- 修改课程对话框的相关代码 -->
			<el-dialog @opened="showup()" @closed="hideup()" title="课程审核" :visible.sync="dialogFormVisibleupdate"
				destroy-on-close>
	
				<el-form :model="updateRuleForm" :rules="updateRules" ref="updateRuleForm" label-width="130px"
					class="demo-ruleForm">
					<!-- <el-form-item label="课程分类" prop="sid">
						<el-cascader :props="{ expandTrigger: 'hover' }" :options="options" v-model="updateRuleForm.sid"
							:show-all-levels="false"></el-cascader> &nbsp;&nbsp;&nbsp;&nbsp;
						<el-button type="primary" size="small" @click="addSubClassify" icon="el-icon-circle-plus-outline">添加小分类</el-button>
					</el-form-item> -->
					<el-form-item label="课程名称:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<span>{{obj.co_name}}</span>
					</el-form-item>
					<el-form-item label="校区名称:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<span>{{obj.c_name}}</span>
					</el-form-item>
					<el-form-item label="所属机构:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<span>{{obj.i_name}}</span>
					</el-form-item>
					<el-form-item label="创建时间:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<span>{{obj.create_time | formatTimer}}</span>
					</el-form-item>
					<el-form-item label="校区图片:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<div class="images" v-for="imgs in images" >
							<el-image
								  style="width: 100px; height: 100px"
								  :src="imgs"
								  fit="scale-down">
							</el-image>
						</div>
						<!-- <span>{{obj.c_name}}</span> -->
					</el-form-item>
					<el-form-item label="大分类:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<span>{{obj.cl_name}}</span>
					</el-form-item>
					<el-form-item label="小分类:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<span>{{obj.s_name}}</span>
					</el-form-item>
					<el-form-item label="到店礼:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<span v-if="obj.co_gify !== null">{{obj.co_gify}}</span>
						<span v-if="obj.co_gify === null">无</span>
					</el-form-item>
					<el-form-item label="课程简介:" >
						<!-- <el-input v-model="updateRuleForm.vip_create_time"></el-input> -->
						<span v-html="obj.co_info"></span>
					</el-form-item>
					<!-- <el-form-item label="校区图片:" >
						<div class="images" v-for="imgs in images" >
							<el-image
								  style="width: 100px; height: 100px"
								  :src="imgs"
								  fit="scale-down">
							</el-image>
						</div>
					</el-form-item> -->
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="danger" @click="showReview()">驳 回</el-button>
					<el-button type="primary" @click="submitSuccess()">通 过</el-button>
				</div>
			</el-dialog>
			
			
			<!-- 驳回对话框相关代码 -->
			<el-dialog @opened="showReview()" @closed="hideReview()" title="驳回校区" :visible.sync="dialogReview"
				destroy-on-close>
				<el-form :model="ruleFormClassify" :rules="rulesClassify" ref="ruleFormClassify" label-width="100px"
					class="demo-ruleForm">
					<!-- <el-form-item label="课程大分类" prop="cId">
						<el-select v-model="ruleFormClassify.cId" placeholder="请选择大分类">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="课程小分类" prop="sName">
						<el-input v-model="ruleFormClassify.sName"></el-input>
					</el-form-item> -->
					<el-form-item label="驳回原因" >
						<el-input type="textarea" show-word-limit clearable :rows="5" v-model="reason" ></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submitReviewFail()">提 交</el-button>
				</div>
			</el-dialog>
	
	
			<!-- 添加分类对话框相关代码 -->
			<el-dialog @opened="showClassify()" @closed="hideClassify()" title="新增小分类" :visible.sync="dialogAddClassify"
				destroy-on-close>
				<el-form :model="ruleFormClassify" :rules="rulesClassify" ref="ruleFormClassify" label-width="100px"
					class="demo-ruleForm">
					<el-form-item label="课程大分类" prop="cId">
						<el-select v-model="ruleFormClassify.cId" placeholder="请选择大分类">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="课程小分类" prop="sName">
						<el-input v-model="ruleFormClassify.sName"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="hideClassify()">取 消</el-button>
					<el-button type="primary" @click="submitFormClassify('ruleFormClassify')">提 交</el-button>
				</div>
			</el-dialog>
	
	
	
	
			<div class="floot">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryInfo.page" :page-sizes="[10, 15, 20, 25]" :page-size="10"
					layout="total, sizes, prev, pager, next, jumper" :total="Total">
				</el-pagination>
			</div>
		</div>
	</template>
	
	<script>
		import wangEditor from 'wangeditor'
		import OSS from 'ali-oss'
		import qs from 'qs'
		import {
			v4 as uuidv4
		} from 'uuid'
	
		import oss from '../../utils/oss'
		export default {
			created() {
				// this.iid = window.sessionStorage.getItem("iid") * 1
				// this.queryInfo.i_id = this.iid
				this.getCoursesList();
				// this.getAllClassify();
				// this.availableAllCampus();
			},
			data() {
				return {
					dialogReview: false,
					defaultValue:'',
					courses: [],
					iid: '',
					campuses: [],
					Total: 0,
					tatalPage: 0,
					queryInfo: {
						co_id: '', //机构的地址
						endtime: '', //结束时间
						pageSize: 10, //每页的数据量
						page: 1, //当前页数
						starttime: '', //开始时间
						i_name: '' ,//机构名称,
						co_name:'',  //机构的状态
						phone:'',
						is_pass: 1,
						c_name: '',
						c_id: '',
						i_id:''
					},
					value: false,
					deletedParam: {
						id: 0
					},
					dialogFormVisible: false,
					dialogFormVisibleupdate: false,
					formLabelWidth: '120px',
					ruleForm: {
						i_name: '',
						phone: '',
						check_num: 0,
						is_vip: ''
					},
					updateRuleForm: {
						vip_create_time: '',
						i_id: '',
						check_num: 0
					},
					ruleFormClassify: {
						cId: '',
						sName: '',
						iid: 0
					},
					updateData: {
						cName: '',
						address: '',
						image: '',
						describes: '',
						isPass: 1,
						cId: 0
					},
					updateImageTemp: [], //修改操作时新上传的图片文件暂存区
					updateImagedd: [], //修改操作全部文件的暂存区
					rules: {
						i_name: [{
								required: true,
								message: '请输入课机构名称',
								trigger: 'blur'
							},
							{
								min: 3,
								max: 20,
								message: '长度在 3 到 20 个字符',
								trigger: 'blur'
							}
						],
						phone: [{
							required: true,
							message: '请输入联系方式',
							trigger: 'blur'
						},{
							pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
							message: '请输入正确的手机号',
							trigger: 'blur'
						}
						],
						check_num: [{
							required: false,
							message: '请填写剩余查看次数',
							trigger: 'blur'
						}],
						is_vip: [{
							required: true,
							message: '请选择是否开户',
							trigger: 'blur'
						}]
					},
					rulesClassify: {
						sName: [{
								required: true,
								message: '请输入小分类名称',
								trigger: 'blur'
							},
							{
								min: 3,
								max: 20,
								message: '长度在 2 到 20 个字符',
								trigger: 'blur'
							}
						],
						cId: [{
							required: true,
							message: '请选择校区',
							trigger: 'blur'
						}]
					},
					updateRules: {
						vip_create_time: [{
							required: true,
							message: '请输入会员到期时间',
							trigger: 'blur'
						}],
						check_num:[{
							required: true,
							message: '请输入查看次数',
							trigger: 'blur'
						}]
					},
					editor: null,
					dialogImageUrl: '', //图片地址
					dialogVisible: false, //是否允许图片
					dialogVisibleupdate: false,
					imageArray: [],
					updateImageArray: [],
					options: [],
					availableCampus: [],
					dialogAddClassify: false,
					obj:{},
					images:[],
					reason:''
				}
			},
			methods: {
				submitReviewFail(){
					this.$http({
						url: 'company/course/reviewCourseFail',
						method: 'POST',
						params: {
							is_pass: 3,
							co_id: this.obj.co_id,
							u_id: this.obj.u_id,
							reason: this.reason,
							co_name: this.obj.co_name
						}
					}).then(res=>{
						if(res.data.code === 200){
							this.$message.success(res.data.message)
						}else{
							this.$message.error(res.data.message)
						}
						this.dialogFormVisibleupdate = false
						this.dialogReview = false
						this.getCoursesList();
					})
				},
				hideReview(){
					this.dialogReview = false
					this.reason = ''
				},
				showReview(){
					this.dialogReview = true
					this.reason = ''
				},
				submitSuccess(){
					// console.log("点击了通过按钮")
					// 进行数据库的修改操作
					this.$http({
						url: 'company/course/passCourse',
						method: 'POST',
						params: {
							is_pass: 2,
							co_id: this.obj.co_id
						}
					}).then(res=>{
						// console.log(res)
						if(res.data.code === 200){
							this.$message.success(res.data.message)
						}else{
							this.$message.error(res.data.message)
						}
						this.dialogFormVisibleupdate = false
						this.getCoursesList();
					})
				},
				dateChange(val){
					// console.log('@',val)
					if(val === null){
						val = ''
						this.queryInfo.starttime = val
						console.log('#',this.queryInfo)
					}
					console.log('!',val)
				},
				dateChange1(val){
					// console.log('@',val)
					if(val === null){
						val = ''
						this.queryInfo.endtime = ''
					}
					// console.log('!',val)
				},
				unionSearch() {
					if(this.queryInfo.starttime === null){
						this.queryInfo.starttime = ''
					}
					if(this.queryInfo.endtime === null){
						this.queryInfo.endtime = ''
					}
					this.$http({
						url: 'company/course/allCourseList',
						params: this.queryInfo
					}).then(res => {
						console.log(res)
						//进行页面元素的赋值操作
						this.Total = res.data.data.Total
						this.tatalPage = res.data.data.tatalPage
						this.courses = res.data.data.courseList
					})
				},
				handleSizeChange(val) {
					// console.log(val);
					//设置页面请求参数
					this.queryInfo.pageSize = val
					this.queryInfo.page = 1
					//重新请求数据
					this.$http({
						url: 'company/course/allCourseList',
						params: this.queryInfo
					}).then(res => {
						console.log(res)
						//进行页面元素的赋值操作
						this.Total = res.data.data.Total
						this.tatalPage = res.data.data.tatalPage
						this.courses = res.data.data.courseList
					})
				},
				handleCurrentChange(val) {
					// console.log(`当前页: ${val}`);
					this.queryInfo.page = val
					this.$http({
						url: 'company/course/allCourseList',
						params: this.queryInfo
					}).then(res => {
						console.log(res)
						//进行页面元素的赋值操作
						this.Total = res.data.data.Total
						this.tatalPage = res.data.data.tatalPage
						this.courses = res.data.data.courseList
					})
				},
				availableAllCampus() {
					this.$http({
						url: 'company/campus/availableAllCampus',
						method: 'GET',
						params: {
							iid: this.iid
						}
					}).then(res => {
						// console.log("adasdas",res)
						this.availableCampus = res.data.data
	
					})
				},
				getAllClassify() {
					this.$http({
						url: 'company/subclassif/classifList',
						method: 'GET'
					}).then(res => {
						// console.log("####",res)
						this.options = res.data.data
					})
				},
				addcoursebefore() {
					// console.log('gogogo')
					// if (window.sessionStorage.getItem("campusNum") === '0') {
					// 	this.$message.error('您还没有创建校区，或校区还没有审核通过，无法创建课程')
					// 	return
					// }
					this.dialogFormVisible = true
				},
				async getCoursesList() {
					const {
						data
					} = await this.$http.get('company/course/allCourseList', {
						params: this.queryInfo
					})
					console.log(data)
					if (data.code !== 200) {
						this.$message.error(data.message)
					} else {
						if (data.data.courseList.length === 0) {
							this.courses = []
	
						} else {
							this.courses = data.data.courseList
							this.Total = data.data.Total
							this.tatalPage = data.data.tatalPage
						}
					}
	
				},
				//点击修改按钮时的方法
				handleEdit(index, row) {
					console.log(index, row);
					//在这里进行数据的赋值操作
					// if (row.overdue_time === null) {
					// 	this.updateRuleForm.vip_create_time = ''
					// } else {
					// 	this.updateRuleForm.vip_create_time = row.overdue_time
					// 	// this.defaultValue = new Date(Date.parse(this.updateRuleForm.vip_create_time))
					// 	// this.defaultValue = this.formatDate(this.updateRuleForm.vip_create_time,'yyyy-MM-dd HH:mm:ss')
						
					// }
					
					// console.log('$$$',typeof(this.updateRuleForm.vip_create_time))
					// this.updateRuleForm.i_id = row.i_id
					// this.updateRuleForm.check_num = row.check_num
					//在这里进行数据的判断操作
					// 1重新打开页面的时候，就赋值updateImagedd里面的数值
					// 2第一次打开页面就赋值row里面的数值
					// if(row.image.length !== this.updateRuleForm.image.length){
					// 	// 第一次打开
					// 	this.updateRuleForm.image =this.updateRuleForm.image
					// }else{
					// 	this.updateRuleForm.image = row.image
					// }
					// this.updateRuleForm.coId = row.co_id
					// this.updateRuleForm.coPrice = row.co_price
					// this.updateRuleForm.coInfo = row.co_info
					// this.updateRuleForm.cId = row.c_id
					// this.updateRuleForm.i_id = row.i_id
					// this.updateRuleForm.sId = row.s_id
					// this.updateRuleForm.sid = [row.cl_id,row.s_id]
					this.obj = row
					this.images = this.obj.co_img.split(",")
					// this.images = this.obj.image.split(",")
					// console.log('!!!!!',this.images)
					//打开弹出框
					this.dialogFormVisibleupdate = true
				},
				handleDelete(index, row) {
					console.log(index, row);
				},
				async deletedChange(campusesInfo) {
					// console.log(campusesInfo)
					//false的时候，deleted为0    true的时候deleted为1
					//进行商品的上下架操作
					this.deletedParam.id = campusesInfo.c_id
					console.log(this.deletedParam.id)
					if (campusesInfo.deleted === 0) {
						//下架					
						const {
							data
						} = await this.$http.post('company/course/deleteCourse', qs.stringify(this.deletedParam))
						// console.log(data)
						if (data.code !== 200) {
							return this.$message.error("操作失败，校区未成功下架")
						}
						campusesInfo.deleted = 1
						return this.$message.success("操作成功，校区已成功下架")
						//这个时候修改元数组
					} else {
						//上架
						const {
							data
						} = await this.$http.post('company/course/undeleteCourse', qs.stringify(this.deletedParam))
						// console.log(data)
						if (data.code !== 200) {
							return this.$message.error("操作失败，校区未成功上架")
						}
						campusesInfo.deleted = 0
						return this.$message.success("操作成功，校区已成功上架")
						//这个时候修改元数组
					}
	
				},
				submitForm(formName) {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							//进行sid的数据充足操作
							// this.ruleForm.sId = this.ruleForm.sid[1]
							this.$http({
								url: 'company/sys-user/addInstitution',
								method: 'POST',
								// header: {
								// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
								// },
								data: qs.stringify({
									i_name: this.ruleForm.i_name,
									phone: this.ruleForm.phone,
									check_num: this.ruleForm.check_num,
									is_vip: this.ruleForm.is_vip
								})
							}).then(res => {
								// console.log(res.data)
								// 开始进行校区的判断操作
								if (res.data.code === 200) {
									this.$message.success(res.data.message)
								} else {
									this.$message.error(res.data.message)
								}
								//进行弹出框的关闭操作
								this.dialogFormVisible = false
								this.ruleForm.i_name = ''
								this.ruleForm.phone = ''
								this.ruleForm.check_num = 0
								this.ruleForm.is_vip = ''
								this.getCoursesList()
	
							})
	
						} else {
							// console.log('error submit!!');
							this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
							return false;
						}
					});
				},
				submitFormClassify(formName) {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							//进行sid的数据充足操作
							this.$http({
								url: 'company/subclassif/addSubClassif',
								method: 'POST',
								// header: {
								// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
								// },
								data: qs.stringify({
									cId: this.ruleFormClassify.cId,
									sName: this.ruleFormClassify.sName
								})
							}).then(res => {
								// console.log(res.data)
								// 开始进行校区的判断操作
								if (res.data.code === 200) {
									this.$message.success(res.data.message)
								} else {
									this.$message.error(res.data.message)
								}
								//进行弹出框的关闭操作
								this.dialogAddClassify = false
								this.ruleFormClassify.cId = ''
								this.ruleFormClassify.sName = ''
								this.getAllClassify()
							})
						} else {
							// console.log('error submit!!');
							this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
							return false;
						}
					});
				},
				submitUpdateForm(formName) {
					this.$refs[formName].validate((valid) => {
						if (valid) {
							//进行sid的数据充足操作
							// this.updateRuleForm.sId = this.updateRuleForm.sid[1]
							this.$http({
								url: 'company/sys-user/updateVipInstitution',
								method: 'POST',
								// header: {
								// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
								// },
								data: qs.stringify({
									vip_create_time: this.updateRuleForm.vip_create_time,
									i_id: this.updateRuleForm.i_id,
									check_num: this.updateRuleForm.check_num
								})
							}).then(res => {
								// console.log(res.data)
								// 开始进行校区的判断操作
								if (res.data.code === 200) {
									this.$message.success(res.data.message)
								} else {
									this.$message.error(res.data.message)
								}
								//进行弹出框的关闭操作
								this.dialogFormVisibleupdate = false
								this.updateRuleForm.vip_create_time = ''
								this.updateRuleForm.u_id = ''
								this.getCoursesList()
	
							})
	
						} else {
							// console.log('error submit!!');
							this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
							return false;
						}
					});
				},
				resetForm(formName) {
					this.$refs[formName].resetFields();
				},
				beforeDestroy() {
					// 调用销毁 API 对当前编辑器实例进行销毁
					this.editor.destroy()
					this.editor = null
				},
				getEditorData() {
					// 通过代码获取编辑器内容
					let data = this.editor.txt.html()
					// alert(data)
				},
				//显示添加校区的富文本
				show() {
					const editor = new wangEditor('#demo1')
					// 配置 onchange 回调函数，将数据同步到 vue 中
					editor.config.onchange = (newHtml) => {
						this.ruleForm.coInfo = newHtml
					}
	
					let client = oss.createOssClient1()
					editor.config.customUploadImg = function(resultFiles, insertImgFn) {
						// resultFiles 是 input 中选中的文件列表
						// insertImgFn 是获取图片 url 后，插入到编辑器的方法
						client.put(uuidv4(), resultFiles[0])
							.then(function(res) {
								console.log(res)
								// 上传图片，返回结果，将图片插入到编辑器中
								insertImgFn(res.url)
							}).catch(function(err) {
								console.log(err)
							})
						// oss.ossUploadFile(uuidv4(), resultFiles[0])
						// 	.then(function(res) {
						// 		console.log(res)
						// 		// 上传图片，返回结果，将图片插入到编辑器中
						// 		insertImgFn(res.url)
						// 	}).catch(function(err) {
						// 		console.log(err)
						// 	})	
					}
	
					// 创建编辑器
					editor.create()
					this.editor = editor
				},
				showClassify() {
					this.dialogAddClassify = true
				},
				hideClassify() {
					this.dialogAddClassify = false
					// 清空弹出层的所有数据
					this.ruleFormClassify.cId = ''
					this.ruleFormClassify.sName = ''
	
				},
				showup() {
					// const editor = new wangEditor('#demo2')
					// // 配置 onchange 回调函数，将数据同步到 vue 中
					// editor.config.onchange = (newHtml) => {
					// 	this.updateRuleForm.coInfo = newHtml
					// }
	
					// let client = oss.createOssClient1()
					// editor.config.customUploadImg = function(resultFiles, insertImgFn) {
					// 	// resultFiles 是 input 中选中的文件列表
					// 	// insertImgFn 是获取图片 url 后，插入到编辑器的方法
					// 	client.put(uuidv4(), resultFiles[0])
					// 		.then(function(res) {
					// 			console.log(res)
					// 			// 上传图片，返回结果，将图片插入到编辑器中
					// 			insertImgFn(res.url)
					// 		}).catch(function(err) {
					// 			console.log(err)
					// 		})
					// }
	
					// // 创建编辑器
					// editor.create()
					// editor.txt.html(this.updateRuleForm.coInfo)
					// this.editor = editor
				},
				//隐藏富文本
				hide() {
					// this.$message.success("asdsadsa")
					this.dialogFormVisible = false
					this.ruleForm.address = ''
					this.ruleForm.cName = ''
					this.ruleForm.image = ''
					this.ruleForm.describes = ''
					// 批量删除oss图片
					// this.imageArray.forEach(item => {
					// 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
					// 	if (isDeleted) {
					// 		console.log("删除成功")
					// 	} else {
					// 		console.log("删除失败")
					// 	}
					// })
					this.imageArray = []
	
				},
				hideup() {
					// this.$message.success("asdsadsa")
					this.updateRuleForm.coId = ''
					this.updateRuleForm.coName = ''
					this.updateRuleForm.coGify = ''
					this.updateRuleForm.coPrice = 0
					this.updateRuleForm.coInfo = ''
					this.updateRuleForm.sId = 0
					this.updateRuleForm.sid = []
					this.dialogFormVisibleupdate = false
					// 批量删除oss图片
					// this.updateImageTemp.forEach(item => {
					// 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
					// 	if (isDeleted) {
					// 		console.log("删除成功")
					// 	} else {
					// 		console.log("删除失败")
					// 	}
					// })
					// this.updateImageTemp = []			
				},
				// 删除图片
				// handleRemove(file, fileList) {
				// 	console.log(file, fileList);
				// },
				// // 图片回显
				// handlePictureCardPreview(file) {
				// 	this.dialogImageUrl = file.url;
				// 	this.dialogVisible = true;
				// },
				async ImgUploadRequest(option) {
					// console.log("进入上传方法")
					oss.ossUploadFile(option).then(res => {
						// console.log(res.res.requestUrls)
						this.imageArray.push(res.res.requestUrls[0])
						// console.log(this.imageArray)
						this.ruleForm.image = this.imageArray.join(',')
					})
				},
				async ImgUploadRequestup(option) {
					// console.log("进入上传方法")
					oss.ossUploadFile(option).then(res => {
						//向image中添加图片
						let obj = {
							url: res.res.requestUrls[0]
						}
						this.updateImagedd.push(obj)
						let aa = this.updateImagedd.map(v => v.url)
						this.updateRuleForm.image = aa.join()
						console.log('##', this.updateRuleForm.image)
					})
				},
				removeImage(file, fileList) {},
				// 删除之前，对图片数据进行维护(添加校区)
				removeImagebefore(file, fileList) {
	
					//1调用图片删除的api
					var isDeleted = oss.ossDeleteImages(file)
					if (isDeleted) {
						// 2清除图片的的对应的数组数据
						// console.log(this.imageArray)
						this.imageArray.splice(this.imageArray.findIndex(item => item === file.response.res.requestUrls[0], 1))
						// console.log(this.imageArray)
						if (this.imageArray.length !== 0) {
							this.ruleForm.image = this.imageArray.join(",")
						} else {
							this.ruleForm.image = ''
						}
						this.$message.success("删除成功")
					} else {
						this.$message.success("删除失败")
					}
					return isDeleted
				},
				//  删除之前，对图片数据进行维护(修改校区)
				// 注意：修改校区的时候最后一张图片不能进行删除
				removeImagebeforeup(file, fileList) {
					// console.log(file.response.res.requestUrls[0])
					if (this.updateImagedd.length === 1) {
						this.$message.error('请至少保留一张图片')
						return false
					}
					//获取删除文件的下标
					let img = this.updateImagedd.map(v => v.url)
					var Index = img.findIndex(item => item === file.url)
					//1调用图片删除的api
					// console.log('##',fileList)
					var fileName = file.url.substr(file.url.indexOf('/image'))
					// console.log('$$',fileName)
					var isDeleted = oss.ossDeleteImage(fileName)
	
					if (isDeleted) {
						// 2清除图片的的对应的数组数据
						this.updateImagedd.splice(Index, 1)
						img = this.updateImagedd.map(v => v.url)
						this.updateRuleForm.image = img.join()
						//进行数据库的删除操作
						this.deleteImage()
						this.getCampusList()
						return true
					} else {
						this.$message.success("删除失败")
						return false
					}
				},
				loadSuccess(file, fileList) {
					// console.log("上传成功！", file, fileList)
				},
				deleteImage() {
					this.$http({
						url: 'company/campus/updateImagesById',
						method: 'POST',
						// header: {
						// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
						// },
						data: qs.stringify({
							id: this.updateRuleForm.cId,
							img: this.updateRuleForm.image
						})
					}).then(res => {
						if (res.data.code === 200) {
							this.$message.success("删除成功")
						}
						return res.data
					})
				},
				addSubClassify() {
					// console.log("点了添加小分类按钮")
					this.dialogAddClassify = true;
					//添加弹出层
				},
				formatDate(date, format) {   
				    if (!date) return;   
				    if (!format) format = "yyyy-MM-dd";   
				    switch(typeof date) {   
				        case "string":   
				            date = new Date(date.replace(/-/, "/"));   
				            break;   
				        case "number":   
				            date = new Date(date);   
				            break;   
				    }    
				    if (!date instanceof Date) return;   
				    var dict = {   
				        "yyyy": date.getFullYear(),   
				        "M": date.getMonth() + 1,   
				        "d": date.getDate(),   
				        "H": date.getHours(),   
				        "m": date.getMinutes(),   
				        "s": date.getSeconds(),   
				        "MM": ("" + (date.getMonth() + 101)).substr(1),   
				        "dd": ("" + (date.getDate() + 100)).substr(1),   
				        "HH": ("" + (date.getHours() + 100)).substr(1),   
				        "mm": ("" + (date.getMinutes() + 100)).substr(1),   
				        "ss": ("" + (date.getSeconds() + 100)).substr(1)   
				    };       
				    return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function() {   
				        return dict[arguments[0]];   
				    });                   
				}
			},
			//格式化日期
			filters: {
				formatTimer: function(value) {
					let date = new Date(value);
					let y = date.getFullYear();
					let MM = date.getMonth() + 1;
					MM = MM < 10 ? "0" + MM : MM;
					let d = date.getDate();
					d = d < 10 ? "0" + d : d;
					let h = date.getHours();
					h = h < 10 ? "0" + h : h;
					let m = date.getMinutes();
					m = m < 10 ? "0" + m : m;
					let s = date.getSeconds();
					s = s < 10 ? "0" + s : s;
					return y + "-" + MM + "-" + d + " " + h + ":" + m;
				}
			},
		}
	</script>
	
	<style scoped lang="less">
		.addButton {
			margin-bottom: 20px;
		}
	
		.home {
			width: 100%;
			margin-right: 20px;
			position: relative;
		}
	
		.floot {
			// position: fixed;
			// bottom: 40px;
			margin-top: 20px;
	
			// justify-content: center;
			.el-pagination {
				margin: 0 auto;
			}
		}
	
		.el-row {
			margin-bottom: 20px;
	
			&:last-child {
				margin-bottom: 0;
			}
		}
	
		.el-col {
			border-radius: 4px;
		}
	
		.bg-purple-dark {
			background: #99a9bf;
		}
	
		.bg-purple {
			background: #d3dce6;
		}
	
		.bg-purple-light {
			background: #e5e9f2;
		}
	
		.grid-content {
			border-radius: 4px;
			min-height: 36px;
		}
	
		.row-bg {
			padding: 10px 0;
			background-color: #f9fafc;
		}
		.images{
			margin: 5px 0px 5px 5px;
			text-align: center;
			display: inline;
		}
	</style>
	