<template>
	<div>
		<el-upload v-model="images" :on-success="loadSuccess" :before-remove="removeImagebeforeup"
			:on-remove="removeImage" :file-list="updateImagedd" :http-request="ImgUploadRequestup"
			list-type="picture-card" action="" :limit=6>
			<i class="el-icon-plus"></i>
		</el-upload>
		<el-dialog :visible.sync="dialogVisibleupdate">
			<!-- 在这里进已上传图片的遍历操作 -->
			<!-- <img v-for="img in updateImagedd"  width="100%" src="img" alt=""> -->
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
		<el-button style="margin-top: 20px;" type="primary" @click="updateSwiper">保存设置</el-button>
	</div>
</template>

<script>
	import OSS from 'ali-oss'
	import qs from 'qs'
	import {
		v4 as uuidv4
	} from 'uuid'

	import oss from '../../utils/oss'
	export default {
		created() {
			this.allSwiper()
		},
		data() {
			return {
				images: '',
				updateImagedd: [],
				dialogVisibleupdate: false,
				dialogImageUrl: '',
				sw_id: ''

			}
		},
		methods: {
			allSwiper() {
				this.$http({
					url: 'company/swipers/allSwipers',
					method: 'GET'
				}).then(res => {
					console.log(res)
					// 进行图片的渲染操作
					this.images = res.data.data.sw_image
					this.sw_id = res.data.data.sw_id
					//进行图片的展示操作
					let imgArr = this.images.split(',')
					imgArr.forEach(item => {
						let obj = {
							url: item
						}
						this.updateImagedd.push(obj)
					})
				})
			},
			loadSuccess(file, fileList) {
				// console.log("上传成功！", file, fileList)
			},
			//  删除之前，对图片数据进行维护(修改校区)
			// 注意：修改校区的时候最后一张图片不能进行删除
			removeImagebeforeup(file, fileList) {
				console.log(this.updateImagedd)
				// console.log(file.response.res.requestUrls[0])
				// if (this.updateImagedd.length === 1) {
				// 	this.$message.error('请至少保留一张图片')
				// 	return false
				// }
				//获取删除文件的下标
				let img = this.updateImagedd.map(v => v.url)
				var Index = img.findIndex(item => item === file.url)
				//1调用图片删除的api
				// console.log('##',fileList)
				var fileName = file.url.substr(file.url.indexOf('/image/'))
				// console.log('$$',fileName)
				var isDeleted = oss.ossDeleteImage(fileName)

				if (isDeleted) {
					// 2清除图片的的对应的数组数据
					this.updateImagedd.splice(Index, 1)
					img = this.updateImagedd.map(v => v.url)
					
					// 开始修改数据
					// this.updateRuleForm.image = img.join()
					
					this.images = img.join()
					//进行数据库的删除操作
					this.deleteImage()
					this.getCampusList()
					return true
				} else {
					this.$message.success("删除失败")
					return false
				}
			},
			removeImage(file, fileList) {},
			async ImgUploadRequestup(option) {
				// console.log("进入上传方法")
				oss.ossUploadFile(option).then(res => {
					//向image中添加图片
					// console.log(res.res.requestUrls[0].indexOf('?uploadId'))
					var urll = ''
					if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
						urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
					} else {
						urll = res.res.requestUrls[0]
					}
					console.log('qqqq', urll)
					let obj = {
						// url: res.res.requestUrls[0]
						url: urll
					}
					this.updateImagedd.push(obj)
					let aa = this.updateImagedd.map(v => v.url)
					this.images = aa.join()
					console.log('##', this.images)
				})
			},
			updateSwiper(){
				// console.log("点击了保存按钮")
				this.$http({
					url: 'company/swipers/updateSwiper',
					method: 'POST',
					data: qs.stringify( {
						sw_id: this.sw_id,
						sw_image: this.images
						})
				}).then(res=>{
					console.log(res)
					if(res.data.code === 200){
						this.$message.success(res.data.message)
					}else{
						this.$message.error(res.data.message)
					}
				})
			}
		}
	}
</script>

<style scoped>

</style>
