<template>
	<div>
		<!-- <h1>Welcome</h1> -->
		<div id="">
			<!-- <h1>{{iNum}}</h1>
			<h1>{{cNum}}</h1>
			<h1>{{coNum}}</h1>
			<h1>{{sNum}}</h1> -->
			<el-row :gutter="20">
				<el-col :span="12">
					<div class="grid-content" style="background-color: #5DAF34;">
						<span>机构数量：{{iNum}}</span>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content" style="background-color: #00FFFF">
						<span>校区数量：{{cNum}}</span>
					</div>
				</el-col>
				</el-row>
				<el-row :gutter="20">
				<el-col :span="12">
					<div class="grid-content" style="background-color: #F56C6C;">
						<span>课程数量：{{coNum}}</span>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="grid-content" style="background-color: #409EFF;">
						<span>用户数量：{{sNum}}</span>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		created() {
			this.getNum()
		},
		data() {
			return {
				iNum: '',
				cNum: '',
				coNum: '',
				sNum: ''
			}
		},
		methods: {
			getNum() {
				this.iNum = window.sessionStorage.getItem("iNum")
				this.cNum = window.sessionStorage.getItem("cNum")
				this.coNum = window.sessionStorage.getItem("coNum")
				this.sNum = window.sessionStorage.getItem("sNum")
			}
		}
	}
</script>

<style scoped>
	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		font-size: 40px;
		color: #FFFFFF;
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		height: 400px;
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
</style>
