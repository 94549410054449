<template>
	<div class="main">
		<div style="color: coral; font-size: 20px; padding-left: 30px;margin-bottom: 10px;">选课虫小程序分享设置</div>
		<div>
			<el-form :model="updateRuleForm" :rules="updateRules" ref="updateRuleForm" label-width="120px"
				class="demo-ruleForm">
				<el-form-item label="分享标题" prop="title">
					<el-input v-model="updateRuleForm.title"></el-input>
				</el-form-item>
				<el-form-item label="分享图片" prop="shareImg">
					<!-- <el-input v-model="ruleForm.image"></el-input> -->
					<el-upload v-model="updateRuleForm.shareImg" :on-success="loadSuccess" ref="uploadup"
						:before-upload="Imgbeforuploadup" :before-remove="removeImagebeforeup" :on-remove="removeImage"
						:file-list="updateImagedd" :http-request="ImgUploadRequestup" list-type="picture-card" action=""
						:limit=1>
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisibleupdate">
						<!-- 在这里进已上传图片的遍历操作 -->
						<!-- <img v-for="img in updateImagedd"  width="100%" src="img" alt=""> -->
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<!-- <span style="color: coral;">机构品牌、课程品牌或Logo图片最佳</span> -->
					
				</el-form-item>
			</el-form>
			<div  class="dialog-footer">
				<!-- <el-button @click="hideup()">取 消</el-button> -->
				<el-button type="primary" @click.once="submitUpdateForm('updateRuleForm')">修改</el-button>
			</div>
			
		</div>
		<!-- 分割线 -->
		<div style="color: coral; font-size: 20px; padding-left: 30px;margin-bottom: 10px;margin-top: 50px;">选课虫商家版分享设置</div>
		<div>
			<el-form :model="updateRuleFormm" :rules="updateRuless" ref="updateRuleFormm" label-width="120px"
				class="demo-ruleForm">
				<el-form-item label="分享标题" prop="title">
					<el-input v-model="updateRuleFormm.title"></el-input>
				</el-form-item>
				<el-form-item label="分享图片" prop="shareImg">
					<!-- <el-input v-model="ruleForm.image"></el-input> -->
					<el-upload v-model="updateRuleFormm.shareImg" :on-success="loadSuccesss" ref="uploadup"
						:before-upload="Imgbeforuploadupp" :before-remove="removeImagebeforeupp" :on-remove="removeImagee"
						:file-list="updateImageddd" :http-request="ImgUploadRequestupp" list-type="picture-card" action=""
						:limit=1>
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisibleupdate">
						<!-- 在这里进已上传图片的遍历操作 -->
						<!-- <img v-for="img in updateImagedd"  width="100%" src="img" alt=""> -->
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<!-- <span style="color: coral;">机构品牌、课程品牌或Logo图片最佳</span> -->
					
				</el-form-item>
			</el-form>
			<div  class="dialog-footer">
				<!-- <el-button @click="hideup()">取 消</el-button> -->
				<el-button type="primary" @click.once="submitUpdateFormm('updateRuleForm')">修改</el-button>
			</div>
			
		</div>
	</div>
</template>

<script>
	import OSS from 'ali-oss'
	import qs from 'qs'
	import {
		v4 as uuidv4
	} from 'uuid'

	import oss from '../../utils/oss'
	export default {
		created() {
			this.getShareInfo()
			this.getShareInfo1()
		},
		data() {
			return {
				updateRuleForm: {
					id: 1,
					title: '',
					shareImg: ''
				},
				updateRules: {
					title: [{
							required: true,
							message: '请输入分享标题',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30 个字符',
							trigger: 'blur'
						}
					],
					shareImg: [{
						required: true,
						message: '请上传分享图片',
						trigger: 'blur'
					}]
				},
				updateImagedd: [],
				dialogVisibleupdate: false,
				dialogImageUrl: '', //图片地址
				updateRuleFormm: {
					id: 2,
					title: '',
					shareImg: ''
				},
				updateRuless: {
					title: [{
							required: true,
							message: '请输入分享标题',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30 个字符',
							trigger: 'blur'
						}
					],
					shareImg: [{
						required: true,
						message: '请上传分享图片',
						trigger: 'blur'
					}]
				},
				updateImageddd: [],
				dialogVisibleupdatee: false,
				dialogImageUrll: '', //图片地址
			}
		},
		methods: {
			loadSuccess(file, fileList) {
				// console.log("上传成功！", file, fileList)
			},
			loadSuccesss(file, fileList) {
				// console.log("上传成功！", file, fileList)
			},
			removeImage(file, fileList) {},
			removeImagee(file, fileList) {},
			removeImagebeforeup(file, fileList) {
				// console.log(this.updateImagedd)
				// console.log(file.response.res.requestUrls[0])
				// if (this.updateImagedd.length === 1) {
				// 	this.$message.error('请至少保留一张图片')
				// 	return false
				// }
				//获取删除文件的下标
				let img = this.updateImagedd.map(v => v.url)
				var Index = img.findIndex(item => item === file.url)
				//1调用图片删除的api
				// console.log('##',fileList)
				var fileName = file.url.substr(file.url.indexOf('/image/'))
				// console.log('$$',fileName)
				var isDeleted = oss.ossDeleteImage(fileName)

				if (isDeleted) {
					// 2清除图片的的对应的数组数据
					this.updateImagedd.splice(Index, 1)
					img = this.updateImagedd.map(v => v.url)
					this.updateRuleForm.clImg = img.join()
					//进行数据库的删除操作
					this.deleteImage()
					// this.getCampusList()
					return true
				} else {
					this.$message.success("删除失败")
					return false
				}
			},
			removeImagebeforeupp(file, fileList) {
				// console.log(this.updateImagedd)
				// console.log(file.response.res.requestUrls[0])
				// if (this.updateImagedd.length === 1) {
				// 	this.$message.error('请至少保留一张图片')
				// 	return false
				// }
				//获取删除文件的下标
				let img = this.updateImageddd.map(v => v.url)
				var Index = img.findIndex(item => item === file.url)
				//1调用图片删除的api
				// console.log('##',fileList)
				var fileName = file.url.substr(file.url.indexOf('/image/'))
				// console.log('$$',fileName)
				var isDeleted = oss.ossDeleteImage(fileName)
			
				if (isDeleted) {
					// 2清除图片的的对应的数组数据
					this.updateImageddd.splice(Index, 1)
					img = this.updateImageddd.map(v => v.url)
					this.updateRuleFormm.clImg = img.join()
					//进行数据库的删除操作
					this.deleteImage()
					// this.getCampusList()
					return true
				} else {
					this.$message.success("删除失败")
					return false
				}
			},
			async ImgUploadRequestup(option) {
				// console.log("进入上传方法")
				oss.ossUploadFile(option).then(res => {
					//向image中添加图片
					// console.log(res.res.requestUrls[0].indexOf('?uploadId'))
					var urll = ''
					if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
						urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
					} else {
						urll = res.res.requestUrls[0]
					}
					console.log('qqqq', urll)
					let obj = {
						// url: res.res.requestUrls[0]
						url: urll
					}
					this.updateImagedd.push(obj)
					let aa = this.updateImagedd.map(v => v.url)
					this.updateRuleForm.shareImg = aa.join()
					console.log('##', this.updateRuleForm.shareImg)
				})
			},
			async ImgUploadRequestupp(option) {
				// console.log("进入上传方法")
				oss.ossUploadFile(option).then(res => {
					//向image中添加图片
					// console.log(res.res.requestUrls[0].indexOf('?uploadId'))
					var urll = ''
					if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
						urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
					} else {
						urll = res.res.requestUrls[0]
					}
					console.log('qqqq', urll)
					let obj = {
						// url: res.res.requestUrls[0]
						url: urll
					}
					this.updateImageddd.push(obj)
					let aa = this.updateImageddd.map(v => v.url)
					this.updateRuleFormm.shareImg = aa.join()
					console.log('##', this.updateRuleFormm.shareImg)
				})
			},
			Imgbeforuploadup(file) {
				var that = this
				// console.log("jinfangfa")
				//进行图片类型和大小的限制
				var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
				const extension = testmsg === 'png'
				const extension2 = testmsg === 'jpg'
				const extension3 = testmsg === 'jpeg'
				// const extension4 = testmsg === 'gif'
				const isLt2M = file.size / 1024 / 1024 < 5
				//const isLt2M = file.size / 1024  <= 500
				if (!extension && !extension2 && !extension3) {
					that.$message.error('图片的格式只能是 png、jpg、jpeg格式!')
					that.$refs.uploadup.clearFiles()
					return false; //必须加上return false; 才能阻止
				}
				if (!isLt2M) {
					that.$message.error('图片大小不能超过5M!请重新选择!')
					that.$refs.uploadup.clearFiles()
					return false; //必须加上return false; 才能阻止
				}
				const isSize = new Promise(function(resolve, reject) {
					let width = 200;
					let height = 200;
					let _URL = window.URL || window.webkitURL;
					let img = new Image();
					img.onload = function() {
						let valid = img.width / img.height == (5/4);
						valid ? resolve() : reject();
						if (!valid) {
							that.$refs.uploadup.clearFiles()
						}
					}
					img.src = _URL.createObjectURL(file);
				}).then(() => {
					return file;
				}, () => {
					that.$message.error('图片只能上传5:4尺寸的图片，请重新选择!')
					that.$refs.uploadup.clearFiles()
					return Promise.reject()
					return false; //必须加上return false; 才能阻止
				})
				return extension || extension2 || extension3 && isLt2M && isSize;
			},
			Imgbeforuploadupp(file) {
				var that = this
				// console.log("jinfangfa")
				//进行图片类型和大小的限制
				var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
				const extension = testmsg === 'png'
				const extension2 = testmsg === 'jpg'
				const extension3 = testmsg === 'jpeg'
				// const extension4 = testmsg === 'gif'
				const isLt2M = file.size / 1024 / 1024 < 5
				//const isLt2M = file.size / 1024  <= 500
				if (!extension && !extension2 && !extension3) {
					that.$message.error('图片的格式只能是 png、jpg、jpeg格式!')
					that.$refs.uploadup.clearFiles()
					return false; //必须加上return false; 才能阻止
				}
				if (!isLt2M) {
					that.$message.error('图片大小不能超过5M!请重新选择!')
					that.$refs.uploadup.clearFiles()
					return false; //必须加上return false; 才能阻止
				}
				const isSize = new Promise(function(resolve, reject) {
					let width = 200;
					let height = 200;
					let _URL = window.URL || window.webkitURL;
					let img = new Image();
					img.onload = function() {
						let valid = img.width / img.height == (5/4);
						valid ? resolve() : reject();
						if (!valid) {
							that.$refs.uploadup.clearFiles()
						}
					}
					img.src = _URL.createObjectURL(file);
				}).then(() => {
					return file;
				}, () => {
					that.$message.error('图片只能上传5:4尺寸的图片，请重新选择!')
					that.$refs.uploadup.clearFiles()
					return Promise.reject()
					return false; //必须加上return false; 才能阻止
				})
				return extension || extension2 || extension3 && isLt2M && isSize;
			},
			submitUpdateForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//进行sid的数据充足操作
						// this.updateRuleForm.sId = this.updateRuleForm.sid[1]
						this.$http({
							url: 'company/share/updateShare',
							method: 'POST',
							// header: {
							// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
							// },
							data: qs.stringify({
								id: this.updateRuleForm.id,
								title: this.updateRuleForm.title,
								shareImg: this.updateRuleForm.shareImg
							})
						}).then(res => {
							// console.log(res.data)
							// 开始进行校区的判断操作
							if (res.data.code === 200) {
								this.$message.success(res.data.message)
							} else {
								this.$message.error(res.data.message)
							}
							//进行弹出框的关闭操作
							// this.dialogFormVisibleupdate = false
							// this.updateRuleForm.title = ''
							// this.updateRuleForm.shareImg = ''
							// this.updateRuleForm.id = ''
							this.getShareInfo()
			
						})
			
					} else {
						// console.log('error submit!!');
						this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
						return false;
					}
				});
			},
			submitUpdateFormm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//进行sid的数据充足操作
						// this.updateRuleForm.sId = this.updateRuleForm.sid[1]
						this.$http({
							url: 'company/share/updateShare1',
							method: 'POST',
							// header: {
							// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
							// },
							data: qs.stringify({
								id: this.updateRuleFormm.id,
								title: this.updateRuleFormm.title,
								shareImg: this.updateRuleFormm.shareImg
							})
						}).then(res => {
							// console.log(res.data)
							// 开始进行校区的判断操作
							if (res.data.code === 200) {
								this.$message.success(res.data.message)
							} else {
								this.$message.error(res.data.message)
							}
							//进行弹出框的关闭操作
							// this.dialogFormVisibleupdate = false
							// this.updateRuleForm.title = ''
							// this.updateRuleForm.shareImg = ''
							// this.updateRuleForm.id = ''
							this.getShareInfo1()
			
						})
			
					} else {
						// console.log('error submit!!');
						this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
						return false;
					}
				});
			},
			getShareInfo(){
				this.$http({
					url: 'company/share/shareInfo',
					method: 'GET',
					// header: {
					// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
					// },
					// data: qs.stringify({
					// 	id: this.updateRuleForm.id,
					// 	title: this.updateRuleForm.title,
					// 	shareImg: this.updateRuleForm.shareImg
					// })
				}).then(res => {
					console.log('获取轮播图',res.data)
					// 开始进行校区的判断操作
					if (res.data.code === 200) {
						// this.$message.success(res.data.message)
						this.updateRuleForm.title = res.data.data.title
						this.updateRuleForm.shareImg = res.data.data.shareImg
						this.updateImagedd = []
						this.updateImagedd.push({url: res.data.data.shareImg})
						// this.updateRuleForm.id = res.data.id
						// this.updateImagedd
					} else {
						this.$message.error(res.data.message)
					}
					//进行弹出框的关闭操作
					// this.dialogFormVisibleupdate = false
					// this.updateRuleForm.title = ''
					// this.updateRuleForm.shareImg = ''
					// this.updateRuleForm.id = ''
					// this.getShareInfo()
							
				})
			},
			getShareInfo1(){
				this.$http({
					url: 'company/share/shareInfo1',
					method: 'GET',
					// header: {
					// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
					// },
					// data: qs.stringify({
					// 	id: this.updateRuleForm.id,
					// 	title: this.updateRuleForm.title,
					// 	shareImg: this.updateRuleForm.shareImg
					// })
				}).then(res => {
					console.log('获取轮播图',res.data)
					// 开始进行校区的判断操作
					if (res.data.code === 200) {
						// this.$message.success(res.data.message)
						this.updateRuleFormm.title = res.data.data.title
						this.updateRuleFormm.shareImg = res.data.data.shareImg
						this.updateImageddd = []
						this.updateImageddd.push({url: res.data.data.shareImg})
						// this.updateRuleForm.id = res.data.id
						// this.updateImagedd
					} else {
						this.$message.error(res.data.message)
					}
					//进行弹出框的关闭操作
					// this.dialogFormVisibleupdate = false
					// this.updateRuleForm.title = ''
					// this.updateRuleForm.shareImg = ''
					// this.updateRuleForm.id = ''
					// this.getShareInfo()
							
				})
			}
		}
	}
</script>

<style scoped>
.dialog-footer{
	margin-left: 30px;
}
</style>
