<template>
	
	<div>
		<el-button class="addButton" type="primary" size="medium" @click="addcoursebefore">新增员工</el-button>
		<!-- 校区列表展示 -->
		<el-table :data="courses" style="width: 100%" stripe border>
			<el-table-column label="员工账号" width="300">
				<template slot-scope="scope">
					<i class="el-icon-time"></i>
					<span style="margin-left: 10px">{{ scope.row.username }}</span>
				</template>
			</el-table-column>
			
			<el-table-column label="操作">
				<template slot-scope="scope">
					<!-- <el-button v-if="scope.row.deleted === 0" size="mini" type="success"
					@click="handleEdit(scope.$index, scope.row)">下架</el-button>
				<el-button v-if="scope.row.deleted !== 0" size="mini" type="success"
					@click="handleEdit(scope.$index, scope.row)">上架</el-button> -->
					<!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">详情</el-button> -->
					<!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>




		<!-- 添加员工对话框的相关代码 -->
		<el-dialog @opened="show()" @closed="hide()" title="新增员工" :visible.sync="dialogFormVisible" destroy-on-close>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">			
				<el-form-item label="员工账号" prop="username">
					<el-input v-model="ruleForm.username"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hide()">取 消</el-button>
				<el-button type="primary"  @click.once="submitForm('ruleForm')">提 交</el-button>
			</div>
		</el-dialog>
		
		
		
		
		
		
		
	</div>
</template>

<script>
	import wangEditor from 'wangeditor'
	import OSS from 'ali-oss'
	import qs from 'qs'
	import {
		v4 as uuidv4
	} from 'uuid'

	import oss from '../../utils/oss'
	export default {
		created() {
			this.iid = window.sessionStorage.getItem("iid") * 1
			this.queryInfo.i_id = this.iid
			this.getCoursesList();
			// this.getAllClassify();
			// this.availableAllCampus();
		},
		data() {
			return {
				courses: [],
				iid: '',
				campuses: [],
				queryInfo: {
					c_id: 0, //校区id
					co_name: '',
					i_id: 0, // 机构id
					pageSize: 15,
					page: 1,
					co_id: 0
				},
				value: false,
				deletedParam: {
					id: 0
				},
				dialogFormVisible: false,
				dialogFormVisibleupdate: false,
				formLabelWidth: '120px',
				ruleForm: {
					username: '',
					i_id: 1
				},
				updateData: {
					cName: '',
					address: '',
					image: '',
					describes: '',
					isPass: 1,
					cId: 0
				},
				updateImageTemp: [], //修改操作时新上传的图片文件暂存区
				updateImagedd: [], //修改操作全部文件的暂存区
				rules: {
					clName: [{
							required: true,
							message: '请输入员工账号',
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '大哥，填写手机号哇!',
							trigger: 'blur'
						}
					]
				},
				editor: null,
				dialogImageUrl: '', //图片地址
				dialogVisible: false, //是否允许图片
				dialogVisibleupdate: false,
				imageArray: [],
				updateImageArray: [],
				options: [],
				availableCampus: [],
				dialogAddClassify: false,
				delParam:{
					username: ''
				}
			}
		},
		methods: {
			availableAllCampus(){
				this.$http({
					url: 'company/campus/availableAllCampus',
					method: 'GET',
					params: {iid: this.iid}
				}).then(res =>{
					// console.log("adasdas",res)
					this.availableCampus = res.data.data
					
				})
			},
			// getAllClassify() {
			// 	this.$http({
			// 		url: 'company/sys-user/allWorkerWeb',
			// 		method: 'GET'
			// 	}).then(res => {
			// 		// console.log("####",res)
			// 		this.options = res.data.data
			// 	})
			// },
			addcoursebefore() {
				this.dialogFormVisible = true
				
			},
			async getCoursesList() {
				const {
					data
				} = await this.$http.get('company/sys-user/allWorkerWeb')
				console.log(data)
				if (data.code !== 200) {
					this.$message.error(data.message)
				} else {
					if (data.data.length === 0) {
						this.courses = []
					} else {
						this.courses = data.data
					}
				}

			},
			//点击修改按钮时的方法
			handleEdit(index, row) {
				console.log(index, row);
				//在这里进行数据的赋值操作
				this.updateRuleForm.clName = row.cl_name
				this.updateRuleForm.clImg = row.cl_img
				//在这里进行数据的判断操作
				// 1重新打开页面的时候，就赋值updateImagedd里面的数值
				// 2第一次打开页面就赋值row里面的数值
				// if(row.image.length !== this.updateRuleForm.image.length){
				// 	// 第一次打开
				// 	this.updateRuleForm.image =this.updateRuleForm.image
				// }else{
				// 	this.updateRuleForm.image = row.image
				// }
				this.updateRuleForm.clId = row.cl_id
				this.updateImagedd = []
				this.updateImagedd.push({url: row.cl_img})
				// 进行图片列表的封装操作
				// let imgArr = this.updateRuleForm.image.split(',')
				// imgArr.forEach(item => {
				// 	let obj = {
				// 		url: item
				// 	}
				// 	this.updateImagedd.push(obj)
				// })
				//打开弹出框
				this.dialogFormVisibleupdate = true
			},
			handleDelete(index, row) {
				console.log(index, row);
				console.log('删除的user',row.username)
				this.delParam.username = row.username
				// const {
				// 	data
				// } = await this.$http.post('company/sys-user/deleteWork',qs.stringify(this.delParam))
				
				this.$http({
				  url: 'company/sys-user/deleteWorkWeb',
				  method: 'POST',
				  params: { username: row.username }
				}).then(res => {
				  // console.log(res)
				  if (res.data.code === 200) {
				    this.$message.success(res.data.message)
				    this.getCoursesList();
				  } else {
				    this.$message.error(res.data.message)
				  }
				})
			},
			async deletedChange(campusesInfo) {
				// console.log(campusesInfo)
				//false的时候，deleted为0    true的时候deleted为1
				//进行商品的上下架操作
				this.deletedParam.id = campusesInfo.c_id
				console.log(this.deletedParam.id)
				if (campusesInfo.deleted === 0) {
					//下架					
					const {
						data
					} = await this.$http.post('company/course/deleteCourse', qs.stringify(this.deletedParam))
					// console.log(data)
					if (data.code !== 200) {
						return this.$message.error("操作失败，校区未成功下架")
					}
					campusesInfo.deleted = 1
					return this.$message.success("操作成功，校区已成功下架")
					//这个时候修改元数组
				} else {
					//上架
					const {
						data
					} = await this.$http.post('company/course/undeleteCourse', qs.stringify(this.deletedParam))
					// console.log(data)
					if (data.code !== 200) {
						return this.$message.error("操作失败，校区未成功上架")
					}
					campusesInfo.deleted = 0
					return this.$message.success("操作成功，校区已成功上架")
					//这个时候修改元数组
				}

			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//进行sid的数据充足操作
						// this.ruleForm.sId = this.ruleForm.sid[1]
						this.$http({
							url: 'company/sys-user/addWorkWeb',
							method: 'POST',
							// header: {
							// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
							// },
							data: qs.stringify({
								username: this.ruleForm.username,
								i_id: this.ruleForm.i_id
							})
						}).then(res => {
							// console.log(res.data)
							// 开始进行校区的判断操作
							if (res.data.code === 200) {
								this.$message.success(res.data.message)
							} else {
								this.$message.error(res.data.message)
							}
							//进行弹出框的关闭操作
							this.dialogFormVisible = false
							this.ruleForm.clName = ''
							this.ruleForm.clImg = ''
							this.imageArray = []
							this.getCoursesList()

						})

					} else {
						// console.log('error submit!!');
						this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
						return false;
					}
				});
			},
			submitFormClassify(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//进行sid的数据充足操作
						this.$http({
							url: 'company/subclassif/addSubClassif',
							method: 'POST',
							// header: {
							// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
							// },
							data: qs.stringify({
								cId: this.ruleFormClassify.cId,
								sName: this.ruleFormClassify.sName
							})
						}).then(res => {
							// console.log(res.data)
							// 开始进行校区的判断操作
							if (res.data.code === 200) {
								this.$message.success(res.data.message)
							} else {
								this.$message.error(res.data.message)
							}
							//进行弹出框的关闭操作
							this.dialogAddClassify = false
							this.ruleFormClassify.cId = ''
							this.ruleFormClassify.sName = ''
							this.getAllClassify()
						})
					} else {
						// console.log('error submit!!');
						this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
						return false;
					}
				});
			},
			submitUpdateForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//进行sid的数据充足操作
						// this.updateRuleForm.sId = this.updateRuleForm.sid[1]
						this.$http({
							url: 'company/classify/updateClassify',
							method: 'POST',
							// header: {
							// 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
							// },
							data: qs.stringify({
								clId: this.updateRuleForm.clId,
								clName: this.updateRuleForm.clName,
								clImg: this.updateRuleForm.clImg
							})
						}).then(res => {
							// console.log(res.data)
							// 开始进行校区的判断操作
							if (res.data.code === 200) {
								this.$message.success(res.data.message)
							} else {
								this.$message.error(res.data.message)
							}
							//进行弹出框的关闭操作
							this.dialogFormVisibleupdate = false
							this.updateRuleForm.clName = ''
							this.updateRuleForm.clImg = ''
							this.updateRuleForm.clId = ''
							this.getCoursesList()

						})

					} else {
						// console.log('error submit!!');
						this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			beforeDestroy() {
				// 调用销毁 API 对当前编辑器实例进行销毁
				this.editor.destroy()
				this.editor = null
			},
			getEditorData() {
				// 通过代码获取编辑器内容
				let data = this.editor.txt.html()
				// alert(data)
			},
			//显示添加校区的富文本
			show() {
				// const editor = new wangEditor('#demo1')
				// // 配置 onchange 回调函数，将数据同步到 vue 中
				// editor.config.onchange = (newHtml) => {
				// 	this.ruleForm.coInfo = newHtml
				// }

				// let client = oss.createOssClient1()
				// editor.config.customUploadImg = function(resultFiles, insertImgFn) {
				// 	// resultFiles 是 input 中选中的文件列表
				// 	// insertImgFn 是获取图片 url 后，插入到编辑器的方法
				// 	client.put(uuidv4(), resultFiles[0])
				// 		.then(function(res) {
				// 			console.log(res)
				// 			// 上传图片，返回结果，将图片插入到编辑器中
				// 			insertImgFn(res.url)
				// 		}).catch(function(err) {
				// 			console.log(err)
				// 		})					
				// }

				// // 创建编辑器
				// editor.create()
				// this.editor = editor
			},
			showClassify(){
				this.dialogAddClassify = true
			},
			hideClassify(){
				this.dialogAddClassify = false
				// 清空弹出层的所有数据
				this.ruleFormClassify.cId = ''
				this.ruleFormClassify.sName = ''
				
			},
			showup() {
				
			},
			//隐藏富文本
			hide() {
				// this.$message.success("asdsadsa")
				this.dialogFormVisible = false
				this.ruleForm.address = ''
				this.ruleForm.cName = ''
				this.ruleForm.image = ''
				this.ruleForm.describes = ''
				// 批量删除oss图片
				// this.imageArray.forEach(item => {
				// 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
				// 	if (isDeleted) {
				// 		console.log("删除成功")
				// 	} else {
				// 		console.log("删除失败")
				// 	}
				// })
				this.imageArray = []

			},
			hideup() {
				// this.$message.success("asdsadsa")
				this.updateRuleForm.coId = ''
				this.updateRuleForm.coName = ''
				this.updateRuleForm.coGify = ''
				this.updateRuleForm.coPrice = 0
				this.updateRuleForm.coInfo = ''
				this.updateRuleForm.sId = 0
				this.updateRuleForm.sid = []
				this.dialogFormVisibleupdate = false
				// 批量删除oss图片
				// this.updateImageTemp.forEach(item => {
				// 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
				// 	if (isDeleted) {
				// 		console.log("删除成功")
				// 	} else {
				// 		console.log("删除失败")
				// 	}
				// })
				// this.updateImageTemp = []			
			},
			// 删除图片
			// handleRemove(file, fileList) {
			// 	console.log(file, fileList);
			// },
			// // 图片回显
			// handlePictureCardPreview(file) {
			// 	this.dialogImageUrl = file.url;
			// 	this.dialogVisible = true;
			// },
			async ImgUploadRequest(option) {
				// console.log("进入上传方法")
				oss.ossUploadFile(option).then(res => {
					// console.log(res.res.requestUrls)
					var url = ''
					if(res.res.requestUrls[0].indexOf('?uploadId') !== -1){
						url = res.res.requestUrls[0].slice(0,res.res.requestUrls[0].indexOf('?uploadId'))
					}else{
						url = res.res.requestUrls[0]
					}
					// this.imageArray.push(res.res.requestUrls[0])
					this.imageArray.push(url)
					// console.log(this.imageArray)
					// this.ruleForm.image = this.imageArray.join(',')
					this.ruleForm.clImg = url
					console.log('%%%%%',this.ruleForm.clImg)
				})
			},
			async ImgUploadRequestup(option) {
				// console.log("进入上传方法")
				oss.ossUploadFile(option).then(res => {
					//向image中添加图片
					// console.log(res.res.requestUrls[0].indexOf('?uploadId'))
					var urll = ''
					if(res.res.requestUrls[0].indexOf('?uploadId') !== -1){
						urll = res.res.requestUrls[0].slice(0,res.res.requestUrls[0].indexOf('?uploadId'))
					}else{
						urll = res.res.requestUrls[0]
					}
					console.log('qqqq',urll)
					let obj = {
						// url: res.res.requestUrls[0]
						url: urll
					}
					this.updateImagedd.push(obj)
					let aa = this.updateImagedd.map(v => v.url)
					this.updateRuleForm.clImg = aa.join()
					console.log('##', this.updateRuleForm.clImg)
				})
			},
			removeImage(file, fileList) {},
			// 删除之前，对图片数据进行维护(添加校区)
			removeImagebefore(file, fileList) {

				//1调用图片删除的api
				var isDeleted = oss.ossDeleteImages(file)
				if (isDeleted) {
					// 2清除图片的的对应的数组数据
					// console.log(this.imageArray)
					this.imageArray.splice(this.imageArray.findIndex(item => item === file.response.res.requestUrls[0], 1))
					// console.log(this.imageArray)
					if (this.imageArray.length !== 0) {
						this.ruleForm.clImg = this.imageArray.join(",")
					} else {
						this.ruleForm.clImg = ''
					}
					this.$message.success("删除成功")
				} else {
					this.$message.success("删除失败")
				}
				return isDeleted
			},
			//  删除之前，对图片数据进行维护(修改校区)
			// 注意：修改校区的时候最后一张图片不能进行删除
			removeImagebeforeup(file, fileList) {
				// console.log(this.updateImagedd)
				// console.log(file.response.res.requestUrls[0])
				// if (this.updateImagedd.length === 1) {
				// 	this.$message.error('请至少保留一张图片')
				// 	return false
				// }
				//获取删除文件的下标
				let img = this.updateImagedd.map(v => v.url)
				var Index = img.findIndex(item => item === file.url)
				//1调用图片删除的api
				// console.log('##',fileList)
				var fileName = file.url.substr(file.url.indexOf('/image/'))
				// console.log('$$',fileName)
				var isDeleted = oss.ossDeleteImage(fileName)

				if (isDeleted) {
					// 2清除图片的的对应的数组数据
					this.updateImagedd.splice(Index, 1)
					img = this.updateImagedd.map(v => v.url)
					this.updateRuleForm.clImg = img.join()
					//进行数据库的删除操作
					this.deleteImage()
					this.getCampusList()
					return true
				} else {
					this.$message.success("删除失败")
					return false
				}
			},
			loadSuccess(file, fileList) {
				// console.log("上传成功！", file, fileList)
			},
			deleteImage() {
				this.$http({
					url: 'company/classify/updateImg',
					method: 'POST',
					data: qs.stringify({
						cl_id: this.updateRuleForm.clId,
						cl_img: this.updateRuleForm.clImg
					})
				}).then(res => {
					if (res.data.code === 200) {
						this.$message.success("删除成功")
					}
					return res.data
				})
			},
			addSubClassify(){
				// console.log("点了添加小分类按钮")
				this.dialogAddClassify = true;
				//添加弹出层
			}

		},
		//格式化日期
		filters: {
			formatTimer: function(value) {
				let date = new Date(value);
				let y = date.getFullYear();
				let MM = date.getMonth() + 1;
				MM = MM < 10 ? "0" + MM : MM;
				let d = date.getDate();
				d = d < 10 ? "0" + d : d;
				let h = date.getHours();
				h = h < 10 ? "0" + h : h;
				let m = date.getMinutes();
				m = m < 10 ? "0" + m : m;
				let s = date.getSeconds();
				s = s < 10 ? "0" + s : s;
				return y + "-" + MM + "-" + d + " " + h + ":" + m;
			}
		}
	}
</script>

<style scoped>
	.addButton {
		margin-bottom: 20px;
	}

	.home {
		width: 100%;
		margin-right: 20px;
		position: relative;
	}
</style>
