<template>
	
	<div>
		<!-- <el-button class="addButton" type="primary" size="medium" @click="addcoursebefore">新增优惠券</el-button> -->
		
		<el-row :gutter="20">
			<el-col :span="4">
				<div class="grid-content">
					<el-input v-model="queryInfo.code" placeholder="请输入邀请码"></el-input>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="grid-content">
					<el-date-picker clearable v-model="queryInfo.starttime" type="date" align="center" placeholder="申请起始日期"
						format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss">
					</el-date-picker>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="grid-content">
					<el-date-picker clearable v-model="queryInfo.endtime" type="date" align="center" placeholder="申请结束日期"
						format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss">
					</el-date-picker>
				</div>
			</el-col>
			<el-col :span="2">
				<div class="grid-content">
					<el-button type="primary" @click="unionSearch()">搜索</el-button>
				</div>
			</el-col>
		</el-row>
		<!-- 校区列表展示 -->
		<el-table :data="courses" style="width: 100%" stripe border fit>
			<el-table-column label="申请机构名称" >
				<template slot-scope="scope">
					<i class="el-icon-time"></i>
					<span >{{ scope.row.i_name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="申请时间" >
				<template slot-scope="scope">
					<span >{{ scope.row.create_time | formatTimer}}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="优惠券领取结束时间" width="180">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.cou_endtime | formatTimer}}</span>
				</template>
			</el-table-column> -->
			<el-table-column label="邀请码" >
				<template slot-scope="scope">
					<span >{{ scope.row.code}}</span>
				</template>
			</el-table-column>
			<el-table-column label="申请人" >
				<template slot-scope="scope">
					<span>{{ scope.row.r_name}}</span>
				</template>
			</el-table-column>
			<el-table-column label="申请人联系方式" >
				<template slot-scope="scope">
					<span>{{ scope.row.phone}}</span>
				</template>
			</el-table-column>
			<el-table-column label="申请人地区" >
				<template slot-scope="scope">
					<span>{{ scope.row.valueArea}}</span>
				</template>
			</el-table-column>
			
		</el-table>
		
		<div class="floot">
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.page" :page-sizes="[10, 15, 20, 25]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="Total">
			</el-pagination>
		</div>
		
		
	</div>
</template>

<script>
	import wangEditor from 'wangeditor'
	import OSS from 'ali-oss'
	import qs from 'qs'
	import {
		v4 as uuidv4
	} from 'uuid'

	import oss from '../../utils/oss'
	export default {
		created() {
			// this.iid = window.sessionStorage.getItem("iid") * 1
			// this.queryInfo.i_id = this.iid
			this.getCoursesList();
			// this.getAllClassify();
			// this.availableAllCampus();
		},
		data() {
			return {
				courses: [],
				iid: '',
				campuses: [],
				queryInfo: {
					code: '', 
					pageSize: 10,
					page: 1,
					deleted: 0,
					starttime: '',
					endtime: ''
				},
				value: false,
				deletedParam: {
					id: 0
				},
				dialogFormVisible: false,
				dialogFormVisibleupdate: false,
				formLabelWidth: '120px',
				ruleForm: {
					coName: '',
					coGify: '',
					cId: '',
					coPrice: 0,
					coInfo: '',
					iId: 0,
					sId: 0,
					sid: [],
					listen: '',
					image: '',
					coContext: ''
				},
				updateRuleForm: {
					couId: '',
					couTitle: '',
					couStarttime: '',
					couEndtime: '',
					couMoney: 0,
					couInfo: ''
				},
				ruleFormClassify:{
					couTitle: '',
					couStarttime: '',
					couEndtime: '',
					couMoney: 0,
					couInfo: ''
				},
				updateData: {
					cName: '',
					address: '',
					image: '',
					describes: '',
					isPass: 1,
					cId: 0
				},
				updateImageTemp: [], //修改操作时新上传的图片文件暂存区
				updateImagedd: [], //修改操作全部文件的暂存区
				rules: {
					coName: [{
							required: true,
							message: '请输入课程名称',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 20,
							message: '长度在 3 到 20 个字符',
							trigger: 'blur'
						}
					],
					coPrice: [{
							required: true,
							message: '请输入课程价值',
							trigger: 'blur'
						}
					],
					coInfo: [{
						required: false,
						message: '请填写课程简介',
						trigger: 'blur'
					}],
					cId: [{
						required: true,
						message: '请选择校区',
						trigger: 'blur'
					}],
					sid: [{
						required: true,
						message: '请选择课程分类',
						trigger: 'blur'
					}],
					listen: [{
						required: true,
						message: '请填写课程数量',
						trigger: 'blur'
					}],
					image: [{
						required: true,
						message: '请上传课程图片',
						trigger: 'blur'
					}],
					coContext: [{
						required: true,
						message: '请填写课程大纲',
						trigger: 'blur'
					}]
				},
				rulesClassify: {
					couTitle: [{
							required: true,
							message: '请输入优惠券的名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					couStarttime: [{
						required: true,
						message: '请设置优惠券开始时间',
						trigger: 'blur'
					}],
					couEndtime: [{
						required: true,
						message: '请设置优惠券结束时间',
						trigger: 'blur'
					}],
					couMoney: [{
						required: true,
						message: '请设置优惠券金额',
						trigger: 'blur'
					}],
					couInfo: [{
						required: true,
						message: '请填写优惠券的介绍',
						trigger: 'blur'
					}]
					
				},
				updateRules: {
					couTitle: [{
							required: true,
							message: '请输入优惠券的名称',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					couStarttime: [{
						required: true,
						message: '请设置优惠券开始时间',
						trigger: 'blur'
					}],
					couEndtime: [{
						required: true,
						message: '请设置优惠券结束时间',
						trigger: 'blur'
					}],
					couMoney: [{
						required: true,
						message: '请设置优惠券金额',
						trigger: 'blur'
					}],
					couInfo: [{
						required: true,
						message: '请填写优惠券的介绍',
						trigger: 'blur'
					}]
				},
				editor: null,
				dialogImageUrl: '', //图片地址
				dialogVisible: false, //是否允许图片
				dialogVisibleupdate: false,
				imageArray: [],
				updateImageArray: [],
				options: [],
				availableCampus: [],
				dialogAddClassify: false,
				Total: 0,
				tatalPage: 0
				
			}
		},
		methods: {
			availableAllCampus(){
				this.$http({
					url: 'company/campus/availableAllCampus',
					method: 'GET',
					params: {iid: this.iid}
				}).then(res =>{
					// console.log("adasdas",res)
					this.availableCampus = res.data.data
					
				})
			},
			getAllClassify() {
				this.$http({
					url: 'company/subclassif/allClassify',
					method: 'GET'
				}).then(res => {
					// console.log("####",res)
					this.options = res.data.data
				})
			},
			addcoursebefore() {
				// this.$http({
				// 	url: 'company/campus/isPassNum',
				// 	params: {i_id: this.iid}
				// }).then(res=>{
				// 	console.log(res.data.data)
				// 	if (res.data.data === 0) {
				// 		this.$message.error('您还没有创建校区，或校区还没有审核通过，无法创建课程')
				// 		this.dialogFormVisible = false
				// 	}else{
				// 		this.dialogFormVisible = true
				// 	}
				// })
				// console.log('gogogo')
				// this.dialogFormVisible = true
				this.dialogAddClassify = true;
				
			},
			async getCoursesList() {
				const {
					data
				} = await this.$http.get('company/settled/allSettled',
						{
							params: this.queryInfo
						}
				)
				console.log(data)
				if (data.code !== 200) {
					this.$message.error(data.message)
				} else {
					if (data.data.SettledList.length === 0) {
						this.courses = []
					} else {
						this.courses = data.data.SettledList
						this.Total = data.data.Total
						this.tatalPage = data.data.tatalPage
					}
				}

			},
			unionSearch() {
				this.$http({
					url: 'company/settled/allSettled',
					params: this.queryInfo
				}).then(res => {
					console.log(res)
					//进行页面元素的赋值操作
					this.Total = res.data.data.Total
					this.tatalPage = res.data.data.tatalPage
					this.courses = res.data.data.SettledList
				})
			},
			handleSizeChange(val) {
				// console.log(val);
				//设置页面请求参数
				this.queryInfo.pageSize = val
				this.queryInfo.page = 1
				//重新请求数据
				this.$http({
					url: 'company/settled/allSettled',
					params: this.queryInfo
				}).then(res => {
					console.log(res)
					//进行页面元素的赋值操作
					this.Total = res.data.data.Total
					this.tatalPage = res.data.data.tatalPage
					this.courses = res.data.data.SettledList
				})
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.queryInfo.page = val
				this.$http({
					url: 'company/settled/allSettled',
					params: this.queryInfo
				}).then(res => {
					console.log(res)
					//进行页面元素的赋值操作
					this.Total = res.data.data.Total
					this.tatalPage = res.data.data.tatalPage
					this.courses = res.data.data.SettledList
				})
			},

		},
		//格式化日期
		filters: {
			formatTimer: function(value) {
				let date = new Date(value);
				let y = date.getFullYear();
				let MM = date.getMonth() + 1;
				MM = MM < 10 ? "0" + MM : MM;
				let d = date.getDate();
				d = d < 10 ? "0" + d : d;
				let h = date.getHours();
				h = h < 10 ? "0" + h : h;
				let m = date.getMinutes();
				m = m < 10 ? "0" + m : m;
				let s = date.getSeconds();
				s = s < 10 ? "0" + s : s;
				return y + "-" + MM + "-" + d;
			}
		}
	}
</script>

<style scoped lang="less">
	.addButton {
		margin-bottom: 20px;
	}
		
	.home {
		width: 100%;
		margin-right: 20px;
		position: relative;
	}
		
	.floot {
		// position: fixed;
		// bottom: 40px;
		margin-top: 20px;
		
		// justify-content: center;
		.el-pagination {
			margin: 0 auto;
		}
	}
		
	.el-row {
		margin-bottom: 20px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
		
	.el-col {
		border-radius: 4px;
	}
		
	.bg-purple-dark {
		background: #99a9bf;
	}
		
	.bg-purple {
		background: #d3dce6;
	}
		
	.bg-purple-light {
		background: #e5e9f2;
	}
		
	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}
		
	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
</style>
