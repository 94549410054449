import Vue from 'vue'
import {
	Form,
	FormItem,
	Input,
	Button,
	Message,
	Container,
	Aside,
	Main,
	Header,
	Menu,
	Submenu,
	MenuItem,
	Table,
	TableColumn,
	Switch,
	Dialog,
	Option,
	Select,
	Upload,
	Cascader,
	InputNumber,
	Pagination,
	Row,
	Col,
	DatePicker,
	Image,
	Popconfirm
} from 'element-ui'


Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Header)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(Option)
Vue.use(Select)
Vue.use(Upload)
Vue.use(Cascader)
Vue.use(InputNumber)
Vue.use(Pagination)
Vue.use(Row)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(Image)
Vue.use(Popconfirm)
Vue.prototype.$message = Message

