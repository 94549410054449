<template>
	<div>
		<div>
			 <el-button type="primary" @click="sendLink">选课虫小程序</el-button>
		</div>
		
		
		
		<!-- 添加短信内容相关代码 -->
		<el-dialog @opened="show()" @closed="hide()" title="推广选课虫小程序" :visible.sync="dialogFormVisible" destroy-on-close>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">	
				<el-form-item label="生成短链" prop="coName">
					<el-button type="primary" @click="createLink">生成短链</el-button>
				</el-form-item>
				<el-form-item label="添加联系人" prop="coGify">
					<!-- <el-input v-model="ruleForm.coGify"></el-input> -->
					<el-button type="primary" @click="addPeople">添加联系人</el-button>
				</el-form-item>
				
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hide()">取 消</el-button>
				<el-button type="primary"  @click.once="submitForm('ruleForm')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				dialogFormVisible: false
			}
		},
		methods:{
			sendLink(){
				this.dialogFormVisible = true
			},
			addPeople(){
				console.log('开始选择联系人')
				
			}
		}
	}
</script>

<style scoped>

</style>
