<template>
	<el-container class="home-container">
		<el-header>
			<div>
				<span>公司总后台</span>
			</div>
			<el-button type="info" @click="logout">退出</el-button>
		</el-header>
		<el-container>
			<el-aside :width="isCollapse ? '64px' : '200px'">
				<div class="toggle-button" @click="toggleCollpase">|||</div>
				<el-menu background-color="#313743" text-color="#fff" active-text-color="#ffd04b" unique-opened :collapse="isCollapse"
          :collapse-transition="false" :router="true" :default-active="activePath" >
					<el-submenu index="1">
						<template slot="title">
							<i class="el-icon-s-home"></i>
							<span>用户管理</span>
						</template>
						<el-menu-item index="user" @click="activeCollapse('user')" >
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>用户列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="2">
						<template slot="title">
							<i class="el-icon-s-claim"></i>
							<span>机构管理</span>
						</template>
						<el-menu-item index="institution" @click="activeCollapse('institution')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>机构列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
						<el-menu-item index="campus" @click="activeCollapse('campus')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>校区列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
						<!-- <el-menu-item index="2-2">
							<template slot="title">
								
								<i class="el-icon-menu"></i>
								
								<span>新增课程</span>
								
							</template>
						</el-menu-item> -->
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">
							<i class="el-icon-phone-outline"></i>
							<span>预约管理</span>
						</template>
						<el-menu-item index="subscribe" @click="activeCollapse('subscribe')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>预约列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
						<!-- <el-menu-item index="newsubscribe" @click="activeCollapse('newsubscribe')">
							<template slot="title">
								
								<i class="el-icon-menu"></i>
								
								<span>新增预约</span>
								
							</template>
						</el-menu-item> -->
					</el-submenu>
					<el-submenu index="4">
						<template slot="title">
							<i class="el-icon-s-order"></i>
							<span>审核管理</span>
						</template>
						<el-menu-item index="campusReview" @click="activeCollapse('campusReview')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>校区审核</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
						<el-menu-item index="courseReview" @click="activeCollapse('courseReview')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>课程审核</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="5">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span>轮播图管理</span>
						</template>
						<el-menu-item index="swipers" @click="activeCollapse('swipers')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>轮播图设置</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="6">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span>分类管理</span>
						</template>
						<el-menu-item index="classify" @click="activeCollapse('classify')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>大分类设置</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
						<el-menu-item index="subclassify" @click="activeCollapse('subclassify')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>小分类设置</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="7">
						<template slot="title">
							<i class="el-icon-money"></i>
							<span>优惠券管理</span>
						</template>
						<el-menu-item index="coupons" @click="activeCollapse('coupons')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>优惠券列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="8">
						<template slot="title">
							<i class="el-icon-dessert"></i>
							<span>套餐管理</span>
						</template>
						<el-menu-item index="plans" @click="activeCollapse('plans')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>套餐列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="9">
						<template slot="title">
							<i class="el-icon-dessert"></i>
							<span>订单管理</span>
						</template>
						<el-menu-item index="order" @click="activeCollapse('order')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>订单列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="10">
						<template slot="title">
							<i class="el-icon-dessert"></i>
							<span>合作申请</span>
						</template>
						<el-menu-item index="settled" @click="activeCollapse('settled')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>申请列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="11">
						<template slot="title">
							<i class="el-icon-dessert"></i>
							<span>小程序share</span>
						</template>
						<el-menu-item index="shared" @click="activeCollapse('shared')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>分享设置</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="12">
						<template slot="title">
							<i class="el-icon-dessert"></i>
							<span>员工管理</span>
						</template>
						<el-menu-item index="worker" @click="activeCollapse('worker')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>员工列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<!-- <el-submenu index="12">
						<template slot="title">
							<i class="el-icon-dessert"></i>
							<span>营销推广</span>
						</template>
						<el-menu-item index="linked" @click="activeCollapse('linked')">
							<template slot="title">
				
								<i class="el-icon-menu"></i>
								
								<span>短信推广</span>
								
							</template>
						</el-menu-item>
					</el-submenu> -->
				</el-menu>
			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	export default {
		created() {
			this.activePath = window.sessionStorage.getItem("activePath")
		},
		data() {
			return {
				isCollapse:false,
				activePath:""
			}
		},
		methods: {
			logout() {
				window.sessionStorage.clear()
				this.$router.push('/login')
			},
			toggleCollpase(){
				this.isCollapse = !this.isCollapse
			},
			activeCollapse(activePath){
				this.activePath = ''
				window.sessionStorage.setItem("activePath",activePath)
				this.activePath = activePath
			}
		}
	}
</script>

<style scoped lang="less">
	.home-container {
		height: 100%;
	}


	.el-aside {
		background-color: #313743;
		.el-menu{
			border-right: none;
		}
	}

	.el-header {
		background-color: #363d3f;
		display: flex;
		justify-content: space-between;
		color: white;
		font-size: 24px;
		align-items: center;
	}

	.el-main {
		background-color: #e9edf0;
	}
	
	
	.toggle-button {
	  background: #4a5064;
	  font-size: 10px;
	  text-align: center;
	  line-height: 24px;
	  color: #fff;
	  letter-spacing: 0.2em;
	  cursor: pointer;
	}
</style>
